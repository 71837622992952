import CookieUtil from '@/assets/js/CookieUtil'
import { RouteHook } from '@/assets/js/HttpUtil'
import { cookieTypes, StoreTypes } from '@/assets/js/Types'
import Vue from 'vue'
import store from "../store/index"
import VueRouter, { RouteConfig } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import MainView from '../views/MainView.vue'
import AdminView from '../views/AdminView.vue'
import MapView from '../views/Admin/MapView.vue'
import DataView from '../views/Admin/DataView.vue'
import DeviceView from '../views/Admin/DeviceView.vue'
import SetView from '../views/Admin/SetView.vue'
import RouterView from '../views/Admin/RouterView.vue'
import CtrView from '../views/Admin/CtrView.vue'
import LoopView from '../views/Admin/LoopNewView.vue'
import SenceView from '../views/Admin/SenceView.vue'
import AlarmView from '../views/Admin/AlarmsView.vue'
import HistoryView from '../views/Admin/HistoryViewNew.vue'
import GatewayDetail from "../views/Admin/GatewayDetail.vue"
import PlatPolicyView from "../views/Admin/PlatPolicyView.vue"
import JingGView from "../views/Admin/JingGView.vue"
import JGDetail from "../views/Admin/JGDetail.vue"
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/Main',
    name: 'amin',
    component: MainView,
    meta: {
      isLogin: true,
      project: true
    }
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: AdminView,
    redirect:"/Admin/OView",
    meta: {
      isLogin: true,
      project: true,
      
    },
    children:[
      {
        path:"/Admin/Map",
        name:"admin.map",
        component:MapView,
        meta: {
          isLogin: true,
          project: true
        }
      },
      {
        path:"/Admin/OView",
        name:"admin.OView",
        component:DataView,
        meta: {
          isLogin: true,
          project: true
        }
      },
      {
        path:"/Admin/PlatPolicy",
        name:"admin.PlatPolicy",
        component:PlatPolicyView,
        meta: {
          isLogin: true,
          project: true
        }
      },
      {
        path:"/Admin/jg",
        name:"admin.jg",
        component:JingGView,
        meta: {
          isLogin: true,
          project: true
        }
      },
      {
        path:"/Admin/Device",
        name:"admin.Device",
        component:DeviceView,
        meta: {
          isLogin: true,
          project: true,
          box:true,
          gateway:true,
          router:true,
          type:true
        }
      },
      {
        path:"/Admin/Setting",
        name:"admin.Setting",
        component:SetView,
        meta: {
          isLogin: true,
          project: true
        }
      },
      {
        path:"/Admin/Router",
        name:"admin.Router",
        component:RouterView,
        meta: {
          isLogin: true,
          project: true
        }
      },
      {
        path:"/Admin/Router",
        name:"admin.Router",
        component:RouterView,
        meta: {
          isLogin: true,
          project: true
        }
      },
      {
        path:"/Admin/Alarms",
        name:"admin.Alarms",
        component:AlarmView,
        meta: {
          isLogin: true,
          project: true
        }
      },
      {
        path:"/Admin/History",
        name:"admin.History",
        component:HistoryView,
        meta: {
          isLogin: true,
          project: true
        }
      },
      {
        path:"/Admin/Ctr",
        name:"admin.Ctr",
        component:CtrView,
        meta: {
          isLogin: true,
          project: true
        }},
        {
          path:"/Admin/Loop",
          name:"admin.Loop",
          component:LoopView,
          meta: {
            isLogin: true,
            project: true
          }
        },
        {
          path:"/Admin/Sence",
          name:"admin.Sence",
          component:SenceView,
          meta: {
            isLogin: true,
            project: true,
            type:true
          }
        },
        {
          path:"/Admin/GatewayDetail",
          name:"admin.GatewayDetail",
          component:GatewayDetail,
          meta: {
            isLogin: true,
            project: true,
            router:true,
            type:true
          }
        },
        {
          path:"/Admin/JG/JGDetail",
          name:"admin.jg.jgdetail",
          component:JGDetail,
          meta: {
            isLogin: true,
            project: true,
            router:true,
            type:true
          }
        }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const array: string[] = [];
  if (to.meta?.isLogin) {
    const token = CookieUtil.getCookie(cookieTypes.XC_COOKIES_ACCESSTOKEN);
    if (!token || token === 'null') {
      router.push("/");
    }
  }
  if (to.meta?.project) {
    array.push(StoreTypes.XC_PROJECT_INFO);
  }
  if(to.meta?.box){
    array.push(StoreTypes.XC_DEVICE_BOX);
  }
  if(to.meta?.gateway){
    array.push(StoreTypes.XC_DEVICE_GATEWAY);
  }
  if(to.meta?.type){
    array.push(StoreTypes.XC_DEVICE_TYPE);
  }
  if(to.meta?.router){
    array.push(StoreTypes.XC_DEVICE_ROUTER);
  }
  const rhk: RouteHook = new RouteHook(array);
  rhk.action(store, false).then(() => { next() }).catch((err) => {
    throw err;
  })
})
export default router
