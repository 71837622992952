import  {cookieTypes}  from "@/assets/js/Types";
export default {
    setCookie(cname: string, value: any, expiredays: number) {
        const exdate = new Date()
        exdate.setDate(exdate.getDate() + expiredays)
        document.cookie = cname + "=" + escape(value) +
            ((expiredays == null) ? "" : ";expires=" + exdate.toDateString() + ";path=/")
    },
    getCookie(cname: string) {
        if (document.cookie.length > 0) {
            let cstart = document.cookie.indexOf(cname + "=")
            if (cstart != -1) {
                cstart = cstart + cname.length + 1
                let cend = document.cookie.indexOf(";", cstart)
                if (cend == -1) {
                    cend = document.cookie.length
                }
                return unescape(document.cookie.substring(cstart, cend))
            }
        }
        return null;
    },
    unsetCookie(name: string) {
        this.setCookie(name, null, 0);
    },
    setLogin(token: string, username: string,issupper:string,appadmin:string) {
        this.setCookie(cookieTypes.XC_COOKIES_ACCESSTOKEN, token, 360);
        this.setCookie(cookieTypes.XC_COOKIES_USERNAME, username, 360);
        this.setCookie(cookieTypes.XC_COOKIES_SUPPERUSER, issupper, 360);
        this.setCookie(cookieTypes.XC_COOKIES_APPADMIN, appadmin, 360);
    }
}