
import Vue from 'vue'
export default Vue.extend({
    props:["total","size","sid"],
    data(){
      return{
        currentPage:1,
        currentSize:6
      }
    },
    methods:{
      change(val:any){
        this.currentPage=val;
        this.$emit("change",this.currentPage,this.currentSize,this.sid);
      },
      handleSizeChange(val:any){
        this.currentSize=val;
        this.currentPage=1;
        this.$emit("change",this.currentPage,this.currentSize,this.sid);
      },
      handleCurrentChange(){
        //
      }
    }
})
