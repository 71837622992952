
import Vue from 'vue'
import sliderCom from "@/components/SliderCom.vue"
import box from "@/components/BoxCom.vue"
import projectInfoCom from "./SetCompoents/ProjectInfoCom.vue"
import projectAdminCom from "./SetCompoents/ProjectAdminCom.vue"
import projectUserCom from "./SetCompoents/ProjectUserCom.vue"
import projectRoleCom from "./SetCompoents/ProjectRoleCom.vue"
import projectPowerCom from "./SetCompoents/ProjectPowerCom.vue"
import projectLog from "./SetCompoents/ProjectLog.vue"
export default Vue.extend({
    components:{sliderCom,box,projectInfoCom,projectAdminCom,projectUserCom,projectRoleCom,projectPowerCom,projectLog},
    data(){
        return{
            currentSid:"0",
            slider:[
                {
                    sid:0,
                    name:"项目信息"
                },
                {
                    sid:1,
                    name:"子项目管理"
                },
                {
                    sid:2,
                    name:"用户管理"
                },
                {
                    sid:3,
                    name:"角色管理"
                },
                {
                    sid:4,
                    name:"权限管理"
                },
                {
                    sid:5,
                    name:"操作日志"
                }
            ]
        }
    },
    methods:{
        change(sid:string){
            this.currentSid=sid;
        }
    }
})
