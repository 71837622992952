import Vue from 'vue'
import Vuex from 'vuex'
import project from "./project"
import device from "./device"
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    freshProject:0 as number,
    alarmAction:0 as number,
    alarmList:[] as any[],
    loopAction:0 as number,
    loopList:[] as any[],
    deviceStateAction:0 as number,
    deviceState:null
  },
  getters: {
  },
  mutations: {
    setFreshProject(state,data){
      state.freshProject=Math.random();
    },
    setAlarmList(state,data){
      state.alarmList=data;
      state.alarmAction=Math.random();
    },
    setLoopList(state,data){
      state.loopList=data;
      state.loopAction=Math.random();
    },
    setDeviceState(state,data){
      state.deviceState=data;
      state.deviceStateAction=Math.random();
    }
  },
  actions: {
  },
  modules: {
    project,device
  }
})
