
import Vue from "vue";
export default Vue.extend({
  props: ["down", "text", "url", "items", "icon"],
  data() {
    return {};
  },
  methods: {
    textclick() {
      this.clicks("");
    },
    handleCommand(command:string) {
      this.clicks(command);
    },
    clicks(command:string){
        this.$emit('itemClick',command);
    },
  }
});
