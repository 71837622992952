
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import { EchartInitModel } from "@/assets/js/Model/InnerModel";
import JGList from "@/assets/js/jg";
import BoxCom from "@/components/BoxCom.vue";
import rlineChart from "@/components/Charts/RLineChartCom.vue";
import Vue from "vue";
export default Vue.extend({

    components: { BoxCom, rlineChart },
    data() {
        return {
            sid: null as any,
            item: {} as any,
            formData: {
                Name: "0",
                Light: "80%",
                Count: "15次",
                Color: "高亮度模式",
                check: true,
                check1: true,
                check2: false,
                check3: false,
                check4: false,
                check5: false,

            } as any
        };
    },
    mounted() {
        this.sid = this.$route.query.sid;
        this.getData();
        this.initChart();
    },
    methods: {
        getData() {
            const items = JGList();
            this.item = items.find(x => x.Sid == this.sid);
        },
        initChart() {
            const labels: string[] = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"];
            const datas: number[] = [];
            for (let i = 0; i < 12; i++) {
                datas.push(Math.random() * (70 - 50) + 50);
            }
            const dchart: any = this.$refs.engChart;
            const data: EchartInitModel = {
                Labels: labels,
                Data: [
                    {
                        Name: "能耗分析",
                        Data: datas,
                        Arear: {
                            opacity: 0.3,
                        },
                    },
                ],
                isXValue: false,
                isInBar: false,
                Style: 0,
                LegendConfig: {
                    textStyle: {
                        color: "#fff",
                    },
                    top: "10",
                },
            };
            dchart.setOption(data);
        },
        async exportClick(){
            //
            const path = await DeviceRequestBill.ExportGatewayeEnger(true, "2023032110090330730900592050ca846");
                window.open(path);
        }
    }
})
