
import Vue from "vue";
import boxCom from "@/components/BoxCom.vue";
import cookies from "@/assets/js/CookieUtil"
import { cookieTypes, StoreTypes } from "@/assets/js/Types";
import  Utils  from "@/assets/js/Utils";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import CookieUtil from "@/assets/js/CookieUtil";
export default Vue.extend({
  components: { boxCom },
  data() {
    return {
        lan:"zh",
      loginForm: {
        userName: "",
        passWord: "",
        checked:false
      }
    };
  },
  async mounted(){
    this.setLan();
    await this.autoLogin();
    const checked=CookieUtil.getCookie(cookieTypes.XC_COOKIES_CHECKED);
    if(checked){
      const userName=CookieUtil.getCookie(cookieTypes.XC_COOKIES_USERNAME);
      const passWord=CookieUtil.getCookie(cookieTypes.XC_COOKIES_PASSWORD);
      this.loginForm.userName=userName||"";
      this.loginForm.passWord=passWord||"";
      this.loginForm.checked=true;
    }
  },
  methods:{
    async autoLogin(){
      const path=window.location.href;
      const jud=path.indexOf("ddl");
      if(jud==-1){
        return;
      }
      const start = path.indexOf("=");
      const end=path.lastIndexOf("#");
      const b64=path.slice(start+1,end);
      const req={
        Data:b64
      }
      const ret:any=await HttpUtil.XCHttpPost(`${document.location.protocol}//kingsunsmart.com/api/smart/GetKSLight`,req,false,false);
      if(ret.RetCode!=0){
        throw "自动登录失败";
      }else{
        const json:any=JSON.parse(ret.Data);
        cookies.setCookie(cookieTypes.XC_COOKIES_ACCESSTOKEN,json.data.token,7);
        cookies.setCookie(cookieTypes.XC_COOKIES_USERNAME,"管理员",7);
        cookies.setCookie(cookieTypes.XC_COOKIES_PROJECTSID,json.data.projectSid,7);
        await this.$store.dispatch(StoreTypes.XC_PROJECT_CURRENT, true);
        await this.$store.dispatch(StoreTypes.XC_PROJECT_INFO, true);
        this.$router.push("/Main");
      }
    },
    async onSubmit(){
      // const form: any = this.$refs.form;
      // const reslut = await form.validate();
      if(Utils.StringIsNull(this.loginForm.userName)||Utils.StringIsNull(this.loginForm.passWord)){
        throw("用户名和密码不能为空");
      }
      const formData={
        userName:this.loginForm.userName,
        password:this.loginForm.passWord,
        remeber:this.loginForm.checked
      };
      const ret:any=await HttpUtil.XCHttpPost(Urls.Login,formData,false,false);
      cookies.setCookie(cookieTypes.XC_COOKIES_ACCESSTOKEN,ret.token,7);
      cookies.setCookie(cookieTypes.XC_COOKIES_USERNAME,this.loginForm.userName,7);
      cookies.setCookie(cookieTypes.XC_COOKIES_PASSWORD,this.loginForm.passWord,7);
      cookies.setCookie(cookieTypes.XC_COOKIES_PROJECTSID,ret.projectSid,7);
      if(this.loginForm.checked){
        cookies.setCookie(cookieTypes.XC_COOKIES_CHECKED,true,7);
      }else{
        cookies.unsetCookie(cookieTypes.XC_COOKIES_CHECKED);
      }
      await this.$store.dispatch(StoreTypes.XC_PROJECT_CURRENT, true);
      await this.$store.dispatch(StoreTypes.XC_PROJECT_INFO, true);
      this.$router.push("/Main");
    },
    changeLan(lan:string){
        this.$i18n.locale = lan;
        cookies.setCookie(cookieTypes.XC_COOKIES_LAN,lan,360);
    },
    setLan(){
        const lan=cookies.getCookie(cookieTypes.XC_COOKIES_LAN);
        if(lan){
            this.lan=lan;
        }else{
            this.lan="zh";
        }
        this.changeLan(this.lan);
    }
  }
});
