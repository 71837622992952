
import menuItem from "@/components/MenuItemCom.vue";
import Vue from "vue";
import { MenuDetail } from "@/assets/js/Model/ResponseModel";
import { StoreTypes } from "@/assets/js/Types";
interface dataModel {
  menus: MenuDetail;
  state: any;
}
export default Vue.extend({
  components: { menuItem },
  data() {
    const ret: dataModel = {
      menus: {
        menuName: "",
        menuPic: "",
        order: "",
        parentMenuSid: "",
        path: "",
        showInLine: "",
        sid: "",
        childrens: []
      },
      state: this.$store.state
    };
    return ret;
  },
  mounted() {
    this.getMenu();
  },
  watch: {
    "$store.state.freshProject"() {
      this.getMenu();
    }
  },
  methods: {
    getMenu() {
      const project = this.$store.state.project[StoreTypes.XC_PROJECT_CURRENT];
      if (project) {
        this.menus = project.menu;
      }
    },
    menuClick(menu: MenuDetail) {
      if(this.$route.path.toLowerCase()!=menu.path.toLowerCase())
        this.$router.push(menu.path);
    }
  }
});
