
import Vue from "vue";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import box from "@/components/BoxCom.vue";
import pageCom from "@/components/PageCom.vue";
import buttonCom from "@/components/ButtonCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
export default Vue.extend({
  components: { pageCom, buttonCom },
  data() {
    return {
      items: [] as any,
      search: null as any,
      currentPage: 1,
      pageSize: 6,
      total: 0,
      choosedItem: [] as string[],
      value1: null as any,
      value2:null as any
    };
  },
  async mounted() {
    const value = new Date();
    this.value1 = new Date(
      value.getFullYear(),
      value.getMonth(),1
    );
    this.value2 = new Date(
      value.getFullYear(),
      value.getMonth(),
      value.getDate()+1
    );
    
    await this.getLights();
  },
  methods: {
    async getLights() {
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetHistoryDatas,
        this.getCondition(),
        true,
        false
      );
      this.total = ret.total;
      this.items = ret.data;
    },
    getCondition() {
      const req = {
        page: this.currentPage,
        pageSize: this.pageSize,
        conditions: [] as any[],
        orderByConditions:[{
          field:"createTime",
          asc:false
        }]
      };
      if (this.search) {
        
        req.conditions.push({
          field: "typeName",
          value: this.search,
          conditionType: 7,
          andCondition:false,
          tableName:"d0"
        });
        req.conditions.push({
          field: "ctrlAddr",
          value: this.search,
          conditionType: 7,
          andCondition:false,
          tableName:"l0"
        });
        req.conditions.push({
          field: "deviceName",
          value: this.search,
          conditionType: 7,
          andCondition:false,
          tableName:"d"
        });
      }
      req.conditions.push({
        field: "updateTime",
        value: this.value1,
          andCondition:true,
        conditionType: 4,
      });
      req.conditions.push({
        field: "updateTime",
        value: this.value2,
          andCondition:true,
        conditionType: 5,
      });
      return req;
    },
    async exportData() {
      const url: string = await HttpUtil.XCHttpPost(
        Urls.ExportHistore,
        this.getCondition(),
        true,
        false
      );
      window.open(url);
    },
    async searchClick() {
      await this.getLights();
    },
    change(val: number, size: number) {
      this.currentPage = val;
      this.pageSize = size;
      this.getLights();
    },
    handleSelectionChange(val: any) {
      this.choosedItem = [];
      val.forEach((x: any) => {
        this.choosedItem.push(x.ctrlAddr);
      });
    },
    getData() {
      return this.choosedItem;
    },
  },
});
