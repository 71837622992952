
import Vue from "vue";
import { autoHover } from "@/assets/js/EchartUtil"
import { EchartInitModel } from "@/assets/js/Model/InnerModel";
interface dataModel {
  echart: any;
  myChart: any;
}
export default Vue.extend({
  data() {
    const ret: dataModel = {
      echart: {},
      myChart: {}
    };
    return ret;
  },
  mounted() {
    this.init();
      window.addEventListener("resize",()=>{
        this.myChart.resize();
      });
  },
  methods: {
    init() {
      this.echart = require("echarts");
      this.myChart = this.echart.init(this.$refs.chart);
    },
    setOption(data: EchartInitModel) {
      const opt: any = {};
      if(data.Colors){
        opt.color=data.Colors;
      }
      if (data.TitleConfig) {
        opt.title = {
          text: data.TitleConfig.title
        };
      }
      opt.tooltip = {
        trigger: "axis",
        axisPointer: {
          type: "cross"
        }
      };
      opt.grid = {
        left: "3%",
        right: "4%",
        bottom: "3%",
        top:"45",
        containLabel: true
      };
        opt.yAxis = {
          type: "value",
            boundaryGap: false,
           splitLine: {
            show: true,
            lineStyle: {
              opacity: 0.2,
              type: "dashed",
            },
          }
        };
        opt.xAxis = {
          type: "category",
          data: data.Labels,
            boundaryGap: false,
           splitLine: {
            show: true,
            lineStyle: {
              opacity: 0.2,
              type: "dashed",
            },
          }
        };
        opt.textStyle= {
          color: "#fff",
        }
      opt.series = [] as any[];
      const stack = data.Data[0].Name;
      const legs:string[]=[];
      data.Data.forEach(x => {
        legs.push(x.Name);
        opt.series.push({
          name: x.Name,
          type: "line",
          barWidth:"30%",
          smooth: true,
          stack: data.isInBar ? stack : x.Name,
          data: x.Data,
          areaStyle: x.Arear
        });
      });
      opt.legend=data.LegendConfig
      this.myChart.setOption(opt);
      if(!data.NoAuto){
        autoHover(this.myChart,opt,4,3000);
      }
    }
  }
});
