
import Vue from "vue";
import adminHeader from "@/components/AdminHeaderCom.vue";
import adminMenu from "@/components/AdminMenuCom.vue";
import SignarlUtil from "@/assets/js/SignarlUtil"
import { StoreTypes } from "@/assets/js/Types";
import { MenuDetail } from "@/assets/js/Model/ResponseModel";
export default Vue.extend({
  components: { adminHeader, adminMenu },
  mounted(){
    const ret=new SignarlUtil(this)
    ret.reStartSign();
    this.getMenu();
  },
  data(){
    return{
      showMenu:false,
      menus:[] as any[]
    }
  },
  methods:{
    sliderClick(){
      this.showMenu=!this.showMenu;
    } ,
     getMenu() {
      const project = this.$store.state.project[StoreTypes.XC_PROJECT_CURRENT];
      if (project) {
        this.menus = project.menu.childrens;
      }
    },
    menuClick(menu: MenuDetail) {
      if(this.$route.path.toLowerCase()!=menu.path.toLowerCase())
        this.$router.push(menu.path);
      this.showMenu=false;
    }
  }
});
