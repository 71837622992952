import { BoxDetail, DeviceTypeDetail, RouterDetail, GatewayDetail } from "@/assets/js/Model/ResponseModel"; 
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import { cookieTypes, StoreTypes } from "@/assets/js/Types";
import Vue from "vue";
import { Commit } from "vuex";
interface state {
    [StoreTypes.XC_DEVICE_ROUTER]: RouterDetail[],
    [StoreTypes.XC_DEVICE_GATEWAY]: GatewayDetail[],
    [StoreTypes.XC_DEVICE_BOX]: BoxDetail[],
    [StoreTypes.XC_DEVICE_TYPE]: DeviceTypeDetail[],
}

const mutations = {
    [StoreTypes.XC_DEVICE_ROUTER]: (state: state, payload: RouterDetail[]): void => {
        state[StoreTypes.XC_DEVICE_ROUTER] = payload;
    },
    [StoreTypes.XC_DEVICE_GATEWAY]: (state: state, payload: GatewayDetail[]): void => {
        state[StoreTypes.XC_DEVICE_GATEWAY] = payload;
    },
    [StoreTypes.XC_DEVICE_BOX]: (state: state, payload: BoxDetail[]): void => {
        state[StoreTypes.XC_DEVICE_BOX] = payload;
    },
    [StoreTypes.XC_DEVICE_TYPE]: (state: state, payload: DeviceTypeDetail[]): void => {
        state[StoreTypes.XC_DEVICE_TYPE] = payload;
    }
}
const getters = {
    [StoreTypes.XC_DEVICE_TYPE]: (state: state): DeviceTypeDetail[] => {
        return state[StoreTypes.XC_DEVICE_TYPE];
    },
    [StoreTypes.XC_DEVICE_GATEWAY]: (state: state): GatewayDetail[] => {
        return state[StoreTypes.XC_DEVICE_GATEWAY];
    },
    [StoreTypes.XC_DEVICE_ROUTER]: (state: state): RouterDetail[] => {
        return state[StoreTypes.XC_DEVICE_ROUTER];
    },
    [StoreTypes.XC_DEVICE_BOX]: (state: state): BoxDetail[] => {
        return state[StoreTypes.XC_DEVICE_BOX];
    },
}

const actions = {
    [StoreTypes.XC_DEVICE_TYPE]: async (context: { commit: Commit; state: state }, fresh: boolean): Promise<void> => {
        if (fresh || !context.state[StoreTypes.XC_DEVICE_TYPE]) {
            context.state[StoreTypes.XC_DEVICE_TYPE] = await (await DeviceRequestBill.GetDeviceTypes()).data;
        }
    },
    [StoreTypes.XC_DEVICE_GATEWAY]: async (context: { commit: Commit; state: state }, fresh: boolean): Promise<void> => {
        if (fresh || !context.state[StoreTypes.XC_DEVICE_GATEWAY]) {
            context.state[StoreTypes.XC_DEVICE_GATEWAY] = await (await DeviceRequestBill.GetGateways(undefined, 1, 1000)).data;
        }
    },
    [StoreTypes.XC_DEVICE_ROUTER]: async (context: { commit: Commit; state: state }, fresh: boolean): Promise<void> => {
        if (fresh || !context.state[StoreTypes.XC_DEVICE_ROUTER]) {
            context.state[StoreTypes.XC_DEVICE_ROUTER] = await (await DeviceRequestBill.GetRouters(undefined, 1, 1000)).data;
        }
    },
    [StoreTypes.XC_DEVICE_BOX]: async (context: { commit: Commit; state: state }, fresh: boolean): Promise<void> => {
        if (fresh || !context.state[StoreTypes.XC_DEVICE_BOX]) {
            context.state[StoreTypes.XC_DEVICE_BOX] = await (await DeviceRequestBill.GetBoxs(undefined, 1, 1000)).data;
        }
    }
}

export default {
    getters: getters,
    mutations: mutations,
    actions: actions
}
