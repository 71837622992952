
import Vue from "vue";
interface dataModel {
  fileList: any[];
}
export default Vue.extend({
  data() {
    const ret: dataModel = {
      fileList: []
    };
    return ret;
  },
  methods: {
    handleRemove(file: any, fileList: any) {
      this.fileList = fileList;
    },
    handlePreview() {
      //
    },
    Uploaded(response: any, file: any) {
      if (file.status == "success") {
        file.url = response.image;
        this.fileList = [];
        this.fileList.push(file);
        this.$emit("Uploaded", file.url);
      } else {
        throw response.RetMsg;
      }
    },
    initPic(pic:string,name:string){
        this.fileList=[];
        this.fileList.push({
            url:pic,
            name:name
        })
    }
  }
});
