
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import pageCom from "@/components/PageCom.vue";
import Vue from "vue";
export default Vue.extend({
  props: ["sid"],
  components:{pageCom},
  data(){
    return{
        total:0,
        pageSize:6,
        currentPage:1,
        items:[] as any[]
    }
  },
  async mounted(){
    await this.getList()
  },
  methods: {
    change(val: number,size:number) {
      this.currentPage = val;
      this.pageSize=size;
      this.getList();
    },
    async getList() {
      var ret=await DeviceRequestBill.GetTaskLog(this.currentPage,this.pageSize,this.sid);
      this.total=ret.total;
      this.items=ret.data;
    },
  },
});
