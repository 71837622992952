export enum StoreTypes{
    XC_PROJECT_INFO="XC_STATE_PROJECT_INFO",
    XC_USERINFO="XC_USERINFO",
    XC_PROJECT_CURRENT="XC_PROJECT_CURRENT",
    XC_PROJECT_CURRENT_FRESH="XC_PROJECT_CURRENT_FRESH",
    XC_DEVICE_BOX="XC_DEVICE_BOX",
    XC_DEVICE_ROUTER="XC_DEVICE_ROUTER",
    XC_DEVICE_GATEWAY="XC_DEVICE_GATEWAY",
    XC_DEVICE_TYPE="XC_DEVICE_TYPE"
}
export enum cookieTypes{
    XC_COOKIES_ACCESSTOKEN="XC_COOKIES_ACCESSTOKEN",
    XC_COOKIES_PROJECTSID="XC_COOKIES_PROJECTSID",
    XC_COOKIES_USERNAME="XC_COOKIES_NAME",
    XC_COOKIES_PASSWORD="XC_COOKIES_PASSWORD",
    XC_COOKIES_CHECKED="XC_COOKIES_CHECKED",
    XC_COOKIES_SUPPERUSER="XC_COOKIES_SUPPERUSER",
    XC_COOKIES_APPADMIN="XC_COOKIES_APPADMIN",
    XC_COOKIES_LAN="XC_COOKIES_LAN"
}