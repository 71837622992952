
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import BoxCom from "@/components/BoxCom.vue";
import editCom from "../Admin/ShareComponents/EditGatewayCom.vue";
import dialogCom from "@/components/ModelCom.vue";
import rlineChart from "@/components/Charts/RLineChartCom.vue";
import Vue from "vue";
import { EchartInitModel } from "@/assets/js/Model/InnerModel";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import { ScreenLocalDetail } from "@/assets/js/Model/ResponseModel";
export default Vue.extend({
  components: { BoxCom, editCom, dialogCom, rlineChart },
  data() {
    return {
      curTime: {
        year: "#" as string,
        month: "#" as string,
        day: "#" as string,
        hour: "#" as string,
        min: "#" as string,
        sec: "#" as string,
      },
      ziggbee: false,
      dataf: "m",
      items: [] as any[],
      sid: null as any,
      loadingDetail: false,
      detailData: { gatewayLoops: [] as any[] } as any,
      projectForm: {} as any,
      current: null as any,
      engerChart: [] as any[],
      passwordForm: {
        serial: "",
        password: "",
      },
      formData: {
        sid: "",
        loopName: "",
        state: 0,
      },
      timeFormat: {
        order: 0,
        startTime: "",
        endTime: "",
        action: 0,
        sid: "",
        delay: 0 as number,
        chanel: [
          {
            channel: 1,
            enable: 1,
          },
        ],
      },
      timmers: [] as any,
    };
  },
  async mounted() {
    this.sid = this.$route.query.sid;
    await this.getDetail();
    await this.datafix("m");
  },
  methods: {
    updateTimmer(sid: string) {
      const item = this.timmers.find((x: any) => x.sid == sid);
      if (item) {
        const dialo: any = this.$refs.timmerDialog;
        this.timeFormat.order = item.order;
        this.timeFormat.startTime = item.startTime;
        this.timeFormat.endTime = item.endTime;
        this.timeFormat.action = item.action;
        this.timeFormat.chanel = item.gatewayChannels;
        this.timeFormat.sid = item.sid;
        this.timeFormat.delay = item.delay * 1;
        if (this.ziggbee) {
          const array: string = item.zigBeeStartTime.split("-");
          if (array.length == 7) {
            this.curTime.year = array[0];
            this.curTime.month = array[1];
            this.curTime.day = array[2];
            this.curTime.hour = array[3];
            this.curTime.min = array[4];
            this.curTime.sec = array[5];
          }
        }
        dialo.show();
      }
    },
    async asyncData() {
      await DeviceRequestBill.GetScreenData(this.detailData.gatewayAddress);
    },
    setPassword() {
      this.passwordForm.password = this.detailData.screenPassword;
      const dialog: any = this.$refs.passwordDialog;
      dialog.show();
    },
    closepasswordDialog() {
      const dialog: any = this.$refs.passwordDialog;
      dialog.hiden();
    },
    async passwordSubmit() {
      this.passwordForm.serial = this.detailData.gatewayAddress;
      await DeviceRequestBill.SetScreenPassword(
        this.passwordForm.serial,
        this.passwordForm.password
      );
      this.closepasswordDialog();
    },
    async setTime() {
      await DeviceRequestBill.SetGatewayTime(this.detailData.gatewayAddress);
    },
    async exp() {
      const year = this.dataf == "y";
      const path = await DeviceRequestBill.ExportGatewayeEnger(year, this.sid);
      window.open(path);
    },
    async read() {
      const req = [this.sid];
      await HttpUtil.XCHttpPost(Urls.ReadGateway, req, true, false);
      window.setTimeout(() => {
        this.getDetail();
      }, 2000);
    },
    async getAlarms() {
      const req = {
        page: 1,
        pageSize: 1,
        conditions: [
          {
            field: "deviceSid",
            value: this.sid,
            conditionType: 0,
          },
        ],
      };
      const ret: any = await HttpUtil.XCHttpPost(Urls.GetDeviceAlarms, req, true, false);
      this.items = ret.data;
    },
    async datafix(fix: string, month: number) {
      this.dataf = fix;
      if (this.dataf == "y") {
        this.engerChart = await this.getEngerByYear();
      } else if (this.dataf == "m") {
        this.engerChart = await this.getEngerByMonth(month);
      }
      this.initEngChart();
    },
    async getEngerByYear() {
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetGatewayChartByYear,
        this.sid,
        true,
        false
      );
      return ret;
    },
    async getEngerByMonth(month: any) {
      const obj = {
        sid: this.sid,
        month: month * 1,
      };
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetGatewayChartByMonth,
        obj,
        true,
        false
      );
      return ret;
    },
    initEngChart() {
      const labels: string[] = [];
      const datas: number[] = [];
      let title = "本年能耗分析";
      if (this.dataf == "y") {
        this.engerChart.forEach((x: any) => {
          labels.push(x.month);
          datas.push(x.energy);
        });
      } else {
        this.engerChart.forEach((x: any) => {
          labels.push(x.date);
          datas.push(x.energy);
          title = "本月能耗分析";
        });
      }
      const dchart: any = this.$refs.engChart;
      const data: EchartInitModel = {
        Labels: labels,
        Data: [
          {
            Name: title,
            Data: datas,
            Arear: {
              opacity: 0.3,
            },
          },
        ],
        isXValue: false,
        isInBar: false,
        Style: 0,
        LegendConfig: {
          textStyle: {
            color: "#fff",
          },
          top: "10",
        },
      };
      dchart.setOption(data);
    },
    setClick() {
      this.current = this.detailData;
      this.projectForm = this.detailData;
      const dialog: any = this.$refs.editCom;
      dialog.showDialog();
    },
    async freshCur() {
      var sids = [this.sid];
      await HttpUtil.XCHttpPost(Urls.GetLoopCurrent, sids, true, false);
      this.getDetail();
    },
    update(sid: string) {
      this.formData.sid = sid;
      const loop: any = this.detailData.gatewayLoops.find((x: any) => x.sid == sid);
      if (loop) {
        this.formData.loopName = loop.loopName;
        this.formData.state = loop.state;
        const dialog: any = this.$refs.updateDialog;
        dialog.show();
      }
    },
    closeUpdateDialog() {
      const dialog: any = this.$refs.updateDialog;
      dialog.hiden();
    },
    closeTimmerDialog() {
      const dialog: any = this.$refs.timmerDialog;
      dialog.hiden();
    },
    async updateSubmit() {
      await HttpUtil.XCHttpPost(Urls.UpdateLoop, this.formData, true, false);
      this.closeUpdateDialog();
      this.getDetail();
    },
    async timmerSubmit() {
      const req: ScreenLocalDetail = {
        serial: this.detailData.gatewayAddress,
        order: this.timeFormat.order,
        action: this.timeFormat.action,
        startTime: this.timeFormat.startTime,
        endTime: this.timeFormat.endTime,
        gatewayChannels: this.timeFormat.chanel,
        delay: this.timeFormat.delay * 1,
        ZigbeeStartTime: "",
      };
      if (this.ziggbee) {
        if (
          this.curTime.year != "#" &&
          (this.curTime.year.length == 0 ||
            parseInt(this.curTime.year) * 1 < 2023 ||
            parseInt(this.curTime.year) * 1 > 9999)
        ) {
          throw "年份格式不对:请填写>2023的年份";
        }
        if (
          this.curTime.month != "#" &&
          (this.curTime.month.length == 0 ||
            parseInt(this.curTime.month) * 1 < 1 ||
            parseInt(this.curTime.month) * 1 > 12)
        ) {
          throw "月份格式不对";
        }
        if (
          this.curTime.day != "#" &&
          (this.curTime.day.length == 0 ||
            parseInt(this.curTime.day) * 1 < 1 ||
            parseInt(this.curTime.day) * 1 > 31)
        ) {
          throw "日期请填写1-31之间的数";
        }
        if (
          this.curTime.hour != "#" &&
          (this.curTime.hour.length == 0 ||
            parseInt(this.curTime.hour) * 1 < 0 ||
            parseInt(this.curTime.hour) * 1 > 24)
        ) {
          throw "小时格式不正确:请填写0-24之间的数";
        }
        if (
          this.curTime.min != "#" &&
          (this.curTime.min.length == 0 ||
            parseInt(this.curTime.min) * 1 < 0 ||
            parseInt(this.curTime.min) * 1 > 60)
        ) {
          throw "分钟请填写0-60之间的数";
        }
        if (
          this.curTime.sec != "#" &&
          (this.curTime.sec.length == 0 ||
            parseInt(this.curTime.sec) * 1 < 60 ||
            parseInt(this.curTime.sec) * 1 > 60)
        ) {
          throw "秒请填写0-60之间的数";
        }
        req.ZigbeeStartTime = `${this.curTime.year}-${this.curTime.month}-${this.curTime.day}-${this.curTime.hour}-${this.curTime.min}-${this.curTime.sec}`;
      }
      await DeviceRequestBill.SetScreentTimmer(req);
      this.closeTimmerDialog();
      await this.getDetail();
    },
    async openChange(isOpen: boolean, sid: string) {
      const data = {
        loopSids: [sid],
        open: isOpen,
      };
      await HttpUtil.XCHttpPost(Urls.ControlLoop, data, true, false);
    },
    async getDetail() {
      const req = {
        page: 1,
        pageSize: 1,
        conditions: [
          {
            field: "sid",
            value: this.sid,
            conditionType: 0,
          },
        ],
      };
      this.loadingDetail = true;
      const ret: any = await HttpUtil.XCHttpPost(Urls.GetLoopList, req, true, true);
      this.loadingDetail = false;
      if (ret.data && ret.data.length != 0) {
        this.detailData = ret.data[0];
        this.timmers = this.detailData.gatewayScreenTimmerInfos;
        if (this.detailData.deviceTypeSid == "6") {
          this.ziggbee = true;
        }
      }
    },
  },
});
