
export  enum Urls{
    Login="/api/Account/Login",
    ExportHistore="/api/HistoryData/Export",
    ExportAlarm="/api/DeviceAlarm/Export",
    UserInfo="/api/Account/UserInfo",
    ChangePassword="/api/Account/ChangePassword",
    Logout="/api/Account/Logout",
    GetProject="/api/Project/Get",
    UpdateProject="/api/Project/Update",
    GetProjects="/api/Project/GetList",
    AddProject="/api/Project/Add",
    DelProject="/api/Project/Delete",
    AddUser="/api/AppUser/Add",
    ActionLog="/api/ActionLog/GetList",
    UpdateUser="/api/AppUser/Update",
    GetUsers="/api/AppUser/GetList",
    DelUsers="/api/AppUser/Delete",
    AddRole="/api/Role/Add",
    UpdateRole="/api/Role/Update",
    GetRoles="/api/Role/GetList",
    GetUserRole="/api/AppUser/GetUserRole",
    DelRole="/api/Role/Delete",
    GetRoleMenu="/api/Role/GetRoleMenu",
    GetMenuTree="/api/Menu/GetMenuTree",
    UpdateRoleMenu="/api/Role/UpdateRoleMenu",
    UpdateUserRole="/api/AppUser/UpdateUserRole",
    GetLights="/api/Light/GetList",
    GetLight="/api/Light/Get",
    AddLight="/api/Light/Add",
    UpdateLight="/api/Light/Update",
    DelLight="/api/Light/Delete",
    AddGroup="/api/Group/Add",
    UpdateGroup="/api/Group/Update",
    DelGroup="/api/Group/Delete",
    GetGroups="/api/Group/GetList",
    AddBox="/api/Box/Add",
    UpdateBox="/api/Box/Update",
    DelBox="/api/Box/Delete",
    GetBoxs="/api/Box/GetList",
    AddGateway="/api/Gateway/Add",
    UpdateGateway="/api/Gateway/Update",
    DelGateway="/api/Gateway/Delete",
    BatchDelete="/api/Gateway/BatchDelete",
    GetGateways="/api/Gateway/GetList",
    GetDeviceTypes="/api/DeviceType/GetList",
    GetLightCtrs="/api/LightControl/GetList",
    CtrDim="/api/LightControl/Dim",
    CtrSwitch="/api/LightControl/Switch",
    CtrRead="/api/LightControl/Read",
    CtrSetLocalTime="/api/LightControl/SetLocalTime",
    CtrGetLocalTime="/api/LightControl/GetLocalTime",
    CtrGetEnergy="/api/LightControl/GetEnergy",
    CtrGetLightState="/api/LightControl/GetLightState",
    CtrGetSunRiseTime="/api/LightControl/GetSunRiseTime",
    
    CtrGroupRead="/api/LightControl/Group/Read",
    CtrGroupDim="/api/LightControl/Group/Dim",
    CtrGroupSwitch="/api/LightControl/Group/Switch",
    
    CtrGroupSetLocalTime="/api/LightControl/Group/SetLocalTime",
    CtrGroupGetLocalTime="/api/LightControl/Group/GetLocalTime",
    CtrGroupGetEnergy="/api/LightControl/Group/ReadEnergy",
    CtrGroupGetLightState="/api/LightControl/Group/GetLightState",
    CtrGroupGetSunRiseTime="/api/LightControl/Group/GetSunRiseTime",

    CtrBoxRead="/api/LightControl/Box/Read",
    CtrBoxDim="/api/LightControl/Box/Dim",
    CtrBoxSwitch="/api/LightControl/Box/Switch",
    CtrBoxSetLocalTime="/api/LightControl/Box/SetLocalTime",
    CtrBoxGetLocalTime="/api/LightControl/Box/GetLocalTime",
    CtrBoxGetEnergy="/api/LightControl/Box/ReadEnergy",
    CtrBoxGetLightState="/api/LightControl/Box/GetLightState",
    CtrBoxGetSunRiseTime="/api/LightControl/Box/GetSunRiseTime",
    
    CtrBroadRead="/api/LightControl/Broadcast/Read",
    CtrBroadDim="/api/LightControl/Broadcast/Dim",
    CtrBroadSwitch="/api/LightControl/Broadcast/Switch",
    CtrBroadSetLocalTime="/api/LightControl/Broadcast/SetLocalTime",
    CtrBroadGetLocalTime="/api/LightControl/Broadcast/GetLocalTime",
    CtrBroadGetEnergy="/api/LightControl/Broadcast/ReadEnergy",
    CtrBroadGetLightState="/api/LightControl/Broadcast/GetLightState",
    CtrBroadcastGetSunRiseTime="/api/LightControl/Broadcast/GetSunRiseTime",
    
    CtrSoftRead="/api/LightControl/SoftwareGroup/Read",
    CtrSoftDim="/api/LightControl/SoftwareGroup/Dim",
    CtrSoftSwitch="/api/LightControl/SoftwareGroup/Switch",
    CtrSoftSetLocalTime="/api/LightControl/SoftwareGroup/SetLocalTime",
    CtrSoftGetLocalTime="/api/LightControl/SoftwareGroup/GetLocalTime",
    CtrSoftGetEnergy="/api/LightControl/SoftwareGroup/ReadEnergy",
    CtrSoftGetLightState="/api/LightControl/SoftwareGroup/GetLightState",
    CtrSoftcastGetSunRiseTime="/api/LightControl/SoftwareGroup/GetSunRiseTime",
    


    GetLoopList="/api/LoopControl/GetList",
    GetGatewayLoopList="/api/GatewayLoop/GetList",
    UpdateLoopLine="/api/GatewayLoop/UpdateLoopLine",
    BatchUpdateLoop="/api/Light/BatchUpdate/Loop",
    AddPolicy="/api/Policy/Add",
    UpdatePolicy="/api/Policy/Update",
    DelPolicy="/api/Policy/Delete",
    GetPolicys="/api/Policy/GetList",
    GetPolicy="/api/Policy/Get",
    AddSoft="/api/SoftwareGroup/Add",
    UpdateSoft="/api/SoftwareGroup/Update",
    DelSoft="/api/SoftwareGroup/Delete",
    GetSofts="/api/SoftwareGroup/GetList",
    GetSoft="/api/SoftwareGroup/Get",
    UpdateSoftDevice="/api/SoftwareGroup/UpdateDevices",
    AddTask="/api/PolicyTask/Add",
    UpdateTask="/api/PolicyTask/Update",
    DelTask="/api/PolicyTask/Delete",
    GetTasks="/api/PolicyTask/GetList",
    GetTask="/api/PolicyTask/Get",
    ExecPolicyTask="/api/PolicyTask/ExecPolicyTask",
    ExecPolicyTaskFix="/api/PolicyTask/ExecPolicyTaskFix",
    GetTaskLogs="/api/PolicyTaskLog/GetList",
    GetHistoryDatas="/api/HistoryData/GetList",
    GetGateListDatas="/api/HistoryData/GetGateList",
    GetDeviceAlarms="/api/DeviceAlarm/GetList",
    ExportExcel="/api/Excel/Export",
    ImportExcel="/api/Excel/Import",
    GetMapData="/api/Dashboard/GetMapData",
    Dashboard="/api/Dashboard/Dashboard",
    BatchPosition="/api/Dashboard/Device/BatchUpdate/Position",
    BatchGroup="/api/Dashboard/Device/BatchUpdate/Group",
    BatchBox="/api/Dashboard/Light/BatchUpdate/Box",
    BatchSoftGroup="/api/Dashboard/Device/BatchUpdate/SoftwareGroup",
    GetDashboard="/api/Dashboard/Dashboard",
    GetDashboardYear="/api/Dashboard/EnergyReport/Year",
    GetDashboardMonth="/api/Dashboard/EnergyReport/Month",
    ExportDashboardMonth="/api/Dashboard/EnergyReport/ExportMonth",
    ExportDashboardYear="/api/Dashboard/EnergyReport/ExportYear",
    GetLightState="/api/Dashboard/Light/GetLightState",
    ResetLightState="/api/DeviceAlarm/ResetLightState",
    UpdateLoop="/api/GatewayLoop/Update",
    GetGatewayChartByMonth="/api/Gateway/Summary/Month",
    GetGatewayChartByYear="/api/Gateway/Summary/Year",
    ReadGateway="/api/LoopControl/ReadGateway",
    GetLoopCurrent="/api/LoopControl/GetLoopCurrent",
    GetScreenDatas="/api/LoopControl/GetScreenDatas",
    SetSunPolicyEnable="/api/LoopControl/SetSunPolicyEnable",
    ControlLoop="/api/LoopControl/Control",
    UpdateLimitValue="/api/Light/BatchUpdate/LimitValue",
    GatewayMonthExport="/api/Gateway/Summary/Month/Export",
    GatewayYearExport="/api/Gateway/Summary/Year/Export",
    SetScreenLocalTime="/api/LoopControl/SetScreenLocalTime",
    SetScreenPassword="/api/LoopControl/SetScreenPassword",
    GetScreenData="/api/LoopControl/GetScreenData",
    SetScreentTimmer="/api/LoopControl/SetScreenTimmer",

    AddHardwareGroup="/api/HardwareGroup/Add",
    UpdateHardwareGroup="/api/HardwareGroup/Update",
    DeleteHardwareGroup="/api/HardwareGroup/Delete",
    GetHardwareGroup="/api/HardwareGroup/Get",
    GetHardwareGroups="/api/HardwareGroup/GetList",
    EditHardwareGroup="/api/HardwareGroup/UpdateDevices",
    SetHardwareGroup="/api/HardwareGroup/SetHardwareGroup",
    ReadHardwareGroup="/api/HardwareGroup/ReadHardwareGroup",
    SwitchHardwareGroup="/api/HardwareGroup/HardwareGroup/Switch",
    DimHardwareGroup="/api/HardwareGroup/HardwareGroup/Dim",

    GetPlatPolicyList="/api/RunPlatPolicy/GetList",
    DelPlatPolicy="/api/RunPlatPolicy/Delete",
    AddPlatPolicy="/api/RunPlatPolicy/Add",
    UpdatePlatPolicy="/api/RunPlatPolicy/Update",
    GetPlatPolicy="/api/RunPlatPolicy/Get",
    EexcutePlatTask="/api/RunPlatPolicy/EexcutePlatTask",

    GetGatewayData="/api/Gateway/GatewayData/GetGatewayData",

    GetEngerData="/api/Gateway/GatewayData/GetEngerData",

    
    GetProjectMenus="/api/Project/GetProjectMenus",
    
    UpdateProjectMenus="/api/Project/UpdateProjectMenus",
    
    GetProjectMenuTree="/api/Project/GetProjectMenuTree",
    
    GetProjectRoleMenuTree="/api/Project/GetProjectRoleMenuTree",
    GatewayControlLoops="/api/Gateway/Gateway/Control",
    AddDeviceToSoftGroup="/api/SoftwareGroup/AddDevices",
    RemoveDeviceToSoftGroup="/api/SoftwareGroup/RemoveDevices",
    AddLoopsToSoftGroup="/api/SoftwareGroup/AddLoops",
    RemoveLoopsToSoftGroup="/api/SoftwareGroup/RemoveLoops"

}

export default Urls;
