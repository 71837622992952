import { render, staticRenderFns } from "./GatewayDetail.vue?vue&type=template&id=fe1f296c&"
import script from "./GatewayDetail.vue?vue&type=script&lang=ts&"
export * from "./GatewayDetail.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports