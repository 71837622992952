import  VueI18n  from 'vue-i18n'
import Vue from 'vue'

// element-plus 中的语言配置
// import elementEnLocale from 'element-plus/lib/locale/lang/en'
// import elementZhLocale from 'element-plus/lib/locale/lang/zh-cn'

// 自己的语言配置
// import enLocale from './en'
// import zhLocale from './zh'
Vue.use(VueI18n);
// 语言配置整合
const messages = {
  en:require("./en.json"),
  zh:require("./zh.json"),
}

// 创建 i18n
const i18n = new VueI18n({
  locale: 'zh',
  messages: messages
})

export default i18n
