
import Vue from "vue";
import box from "@/components/BoxCom.vue";
import rate from "@/components/DataRateCom.vue";
import lineChart from "@/components/Charts/LineChartCom.vue";
import rlineChart from "@/components/Charts/RLineChartCom.vue";
import pieChart from "@/components/Charts/PieCharCom.vue";
import alarmAnimate from "@/components/AlarmAnimateCom.vue";
import { EchartInitModel } from "@/assets/js/Model/InnerModel";
import { getColors } from "@/assets/js/EchartUtil";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
export default Vue.extend({
  components: { box, rate, lineChart, rlineChart ,pieChart,alarmAnimate},
  data() {
    return {
      deviceTitle: {
        pic: require("@/assets/img/icon/icon-title-device.png"),
        title: this.$t("设备统计分析")
      },
      monthTitle: {
        pic: require("@/assets/img/icon/icon-title-device.png"),
        title: this.$t("当月能耗对比分析")
      },
      engTitle: {
        pic: require("@/assets/img/icon/icon-title-eng.png"),
        title: this.$t("累计能耗分析"),
        dateifx: true,
        exbutton:"导出"
      },
      alarmTitle: {
        pic: require("@/assets/img/icon/icon-title-alarmchart.png"),
        title: this.$t("告警分析"),
        dateifx: true
      },
      alarmActionTitle: {
        pic: require("@/assets/img/icon/icon-title-alarm.png"),
        title: this.$t("告警处理"),
        dateifx: true
      },
      realAlarmTitle: {
        pic: require("@/assets/img/icon/icon-title-realalarm.png"),
        title: this.$t("实时告警消息"),
        dateifx: true
      },
      dataf: "d",
      rateDevice: {
        rate: "90%",
        desc: "设备在线率",
        sum:0,
        desc1:"在线数量",
        count:0,
        pic: require("@/assets/img/icon/icon-online.png")
      },
      rateAlarm: {
        rate: "0.01%",
        desc: "设备告警率",
        desc1:"告警数量",
        count:0,
        sum:0,
        pic: require("@/assets/img/icon/icon-alarm.png")
      },
      rateLight: {
        rate: "99.5%",
        desc: "设备亮灯率",
        desc1:"亮灯数量",
        sum:0,
        count:0,
        pic: require("@/assets/img/icon/icon-light.png")
      },
      thisMonthEng: 0,
      lastMonthEng: 0,
      monthEngRate: 0 as number,
      monthType: "",
      Colors: getColors(),
      alarms:[] as any[],
      dashData:{
        alarmReport:{}
      } as any,
      currentAlarm:{} as any,
      engerChart:[] as any[],
      currentMonth:1 as number
    };
  },
  async mounted() {
    await this.getData(); 
    await this.datafix('y',0);
    this.initDeviceChart();
    this.initMonthChart();
    this.initAlarmChart();
  },
  
  watch:{
    "$store.state.alarmAction"(){
      const list:any[]=this.$store.state.alarmList;
      if(list&&list.length>0){
        const first=list[0];
        const animate:any=this.$refs.animate;
        animate.start(true);
        this.currentAlarm.deviceName=first.deviceName;
        this.currentAlarm.deviceTypeName=first.deviceTypeName;
        this.currentAlarm.alarmTypeName=first.alarmTypeName;
        this.currentAlarm.updateTime=first.updateTime;
        list.forEach((x:any)=>{
          this.alarms.push(x);
        })
      }
    }
  },
  methods: {
    currentChange(val:any){
      this.currentAlarm=val;
    },
    async getData(){
      const year=(new Date()).getFullYear();
      const ret:any=await HttpUtil.XCHttpPost(Urls.GetDashboard,{year:year},true,false);
      this.rateDevice.rate=ret.lightOnlinePercent+"%";
      this.rateLight.rate=ret.lightOpenPercent+"%";
      this.rateAlarm.rate=ret.lightAlarmPercent+"%";
      this.rateDevice.sum=ret.lightCount;
      this.rateLight.sum=ret.lightCount;
      this.rateAlarm.sum=ret.lightCount;
      if(ret.deviceStateReports&&ret.deviceStateReports.length!=0){
        const exsit=ret.deviceStateReports.find(x=>x.deviceName=='灯具');
        if(exsit){
          this.rateDevice.count=exsit.onlineCount;
          this.rateAlarm.count=exsit.alarmCount;
          this.rateLight.count=Math.floor(ret.lightOpenPercent*this.rateDevice.count/100);
        }
      }
      this.dashData=ret;
      this.alarms=this.dashData.deviceAlarms;
      if(this.alarms.length>0){
        this.currentChange(this.alarms[0]);
      }
    },
    async datafix(command: string,month:number) {
      this.dataf = command;
      if(this.dataf=='y'){
        this.engerChart=await this.getEngerByYear();
      }else if(this.dataf=='m'){
        this.engerChart=await this.getEngerByMonth(month);
      }
      this.initEngChart();
    },
    async getEngerByYear(){
      const ret :any = await HttpUtil.XCHttpPost(Urls.GetDashboardYear,null,true,false);
      return ret;
    },
    async getEngerByMonth(month:number){
      this.currentMonth=month*1;
      const ret :any = await HttpUtil.XCHttpPost(Urls.GetDashboardMonth,month*1,true,false);
      return ret;
    },
    async exp(){
      if(this.dataf=='y'){
        window.open( await HttpUtil.XCHttpPost(Urls.ExportDashboardYear,null,true,false));
      }else if(this.dataf=='m'){
        window.open( await HttpUtil.XCHttpPost(Urls.ExportDashboardMonth,this.currentMonth*1,true,false));
      }
    },
    initAlarmChart(){
       const dchart: any = this.$refs.alarmChart;
        const data: EchartInitModel = {
          Data:[
            {
              Name:"设备告警分析",
              Data:[
                {
                  value:this.dashData.alarmReport.processAlarmCount,name:"已处理"
                },
                {
                  value:this.dashData.alarmReport.unProcessAlarmCount,name:"未处理"
                }
              ]
            }
          ],
          LegendConfig:{
            bottom:10,
            textStyle:{
              color:"#fff"
            }
          }
        }
        dchart.setOption(data);
    },
    initEngChart() {
      const labels:string[]=[];
      const datas:number[]=[];
      let title="本年能耗分析";
      if(this.dataf=='y'){
        this.engerChart.forEach((x:any)=>{
          x.energy=parseFloat((x.energy*1).toFixed(2))
          labels.push(x.month);
          datas.push(x.energy);
        })
      }else {
        this.engerChart.forEach((x:any)=>{
          labels.push(x.date);
          datas.push(x.energy);
          title="本月能耗分析";
        })
      }
      const dchart: any = this.$refs.engChart;
      const data: EchartInitModel = {
        Labels:labels,
        Data: [
          {
            Name: title,
            Data: datas,
            Arear: {
              opacity: 0.3
            }
          }
        ],
        isXValue: false,
        isInBar: false,
        Style: 0,
        LegendConfig: {
          textStyle: {
            color: "#fff"
          },
          top: "10"
        }
      };
      dchart.setOption(data);
    },
    initMonthChart() {
      const dchart: any = this.$refs.monthChart;
      this.lastMonthEng =parseFloat(Math.round(this.dashData.monthEnergyReport.lastMonthEnergy*1).toFixed(2));
      this.thisMonthEng = parseFloat((this.dashData.monthEnergyReport.currentMonthEnergy*1).toFixed(2));
      const data: EchartInitModel = {
        Labels: ["本月能耗", "上月能耗"],
        Data: [
          {
            Name: "能耗",
            Data: [
               this.thisMonthEng,
              {
                value:  this.lastMonthEng,
                itemStyle: {
                  color: this.Colors[2]
                }
              }
            ]
          }
        ],
        isXValue: false,
        isInBar: true,
        Style: 0
      };
      dchart.setOption(data);
      const lrate :any= (this.lastMonthEng - this.thisMonthEng).toFixed(2);
      if (lrate*1> 0) {
        this.monthType = "下降";
      } else {
        this.monthType = "增加";
        this.monthEngRate=lrate*-1;
      }
    },
    initDeviceChart() {
      const labels:any[]=[];
      const onlineDatas:any[]=[];
      const offlineDatas:any[]=[];
      const alarmDatas:any[]=[];
      this.dashData.deviceStateReports.forEach((x:any) => {
        labels.push(x.deviceName);
        onlineDatas.push(x.onlineCount);
        offlineDatas.push(x.offlineCount);
        alarmDatas.push(x.alarmCount);
      });
      const dchart: any = this.$refs.deviceChart;
      const data: EchartInitModel = {
        Colors: [this.Colors[0], this.Colors[1], this.Colors[2]],
        Labels: labels,
        Data: [
          {
            Name: "在线",
            Data:onlineDatas
          },
          {
            Name: "离线",
            Data: offlineDatas
          },
          {
            Name: "报警",
            Data: alarmDatas
          }
        ],
        isXValue: true,
        isInBar: true,
        Style: 0,
        LegendConfig: {
          textStyle: {
            color: "#fff"
          },
          right: "20",
          top: "10"
        }
      };
      dchart.setOption(data);
    }
  }
});
