
import Vue from "vue";
import sliderCom from "@/components/SliderCom.vue";
import box from "@/components/BoxCom.vue";
import allCom from "./CtrComponents/AllCom.vue";
import routerCom from "./CtrComponents/RouterCom.vue";
import boxCom from "./CtrComponents/BoxCtrCom.vue";
import hardCom from "./CtrComponents/HardCtrCom.vue"
import groupCom from "./CtrComponents/GroupCtrCom.vue";
import { StoreTypes } from "@/assets/js/Types";
import Urls from "@/assets/js/Urls";
import { CtrlType } from "@/assets/js/Model/InnerModel";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
export default Vue.extend({
  components: { sliderCom, box, allCom, routerCom, boxCom, groupCom,hardCom },
  data() {
    return {
      currentSid: "",
      selectType: 0,
      slider: [] as any[],
    };
  },
  mounted() {
    this.getMenu();
  },
  methods: {
    change(sid: string) {
      this.currentSid = sid;
    },
    async ctrLight(ctrType: CtrlType, val: number) {
      let ids: string[] = [];
      let ref: any = null;
      switch (this.currentSid) {
        case "2022082612011125132600405532cc6f0":
          if (this.selectType == 0) {
            ref = this.$refs.all;
            ids = ref.getData();
            await DeviceRequestBill.CtrLightBySignal(ids, ctrType, val);
          } else {
            await DeviceRequestBill.CtrLightByBroad(ctrType, val);
          }
          break;
        case "2022082612011125132600405532cc6f1":
          ref = this.$refs.router;
          ids = ref.getData();
          await DeviceRequestBill.CtrLightByRouter(ids, ctrType, val);
          break;
        case "2022082612011125132600405532cc6f2":
          ref = this.$refs.box;
          ids = ref.getData();
          await DeviceRequestBill.CtrLightByBox(ids, ctrType, val);
          break;
        case "2022082612011125132600405532cc6f3":
          ref = this.$refs.group;
          ids = ref.getData();
          await DeviceRequestBill.CtrLightBySoft(ids, ctrType, val);
          break;
        case "2022082612011125132600405532cc6f4":
          ref = this.$refs.hard;
          ids = ref.getData();
          await DeviceRequestBill.CtrLightByHard(ids, ctrType, val);
          break;
      }
    },
    async asyncTime(){
      await this.ctrLight(CtrlType.同步时间,0);
      this.freshPage();
    },
    async getEnger(){
      await this.ctrLight(CtrlType.电能查询,0);
      this.freshPage();
    },
    async getTime(){
      await this.ctrLight(CtrlType.读取时间,0);
      this.freshPage();
    },
    async getState(){
      await this.ctrLight(CtrlType.灯具状态,0);
      this.freshPage();
    },
    async getSun(){
      
      await this.ctrLight(CtrlType.日出日落时间查询,0);
      this.freshPage();
    },
    async open() {
      await this.ctrLight(CtrlType.开灯, 0);
    },
    async close() {
      await this.ctrLight(CtrlType.关灯, 0);
    },
     freshPage(){
      if (this.currentSid == "2022082612011125132600405532cc6f0") {
        setTimeout(() => {
          const ref: any = this.$refs.all;
          ref.getLights();
        }, 3000);
      }
    },
    async read() {
      await this.ctrLight(CtrlType.读取数据, 0);
      if (this.currentSid == "2022082612011125132600405532cc6f0") {
        setTimeout(() => {
          const ref: any = this.$refs.all;
          ref.getLights();
        }, 3000);
      }
    },
    light() {
      this.$prompt("请输入亮度", "调光", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\d+/,
        inputErrorMessage: "请输入0-100之间的数值",
      })
        .then(async (value: any) => {
          if (value.value < 0 || value.value > 100) {
            this.$message("请输入0-100之间的数值");
          } else {
            try {
              await this.ctrLight(CtrlType.调光, value.value * 1);
            } catch (err: any) {
              this.$message(err);
            }
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    getMenu() {
      const project = this.$store.state.project[StoreTypes.XC_PROJECT_CURRENT];
      if (project && project.menu) {
        if (project.menu.childrens && project.menu.childrens.length != 0) {
          const m: any = project.menu.childrens.find(
            (x: any) => x.path.toLowerCase() == this.$route.path.toLowerCase()
          );
          if (m) {
            this.slider = [];
            m.childrens.forEach((x: any) => {
              this.slider.push({
                sid: x.sid,
                name: x.menuName,
              });
            });
            if (this.slider.length != 0) {
              this.currentSid = this.slider[0].sid;
              const slider: any = this.$refs.slider;
              slider.setCurrentSid(this.slider[0].sid);
            }
          }
        }
      }
    },
  },
});
