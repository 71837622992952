
import Vue from "vue";
import addrCom from "@/components/AddrCom.vue";
import action from "@/components/ActionBarCom.vue";
import pageCom from "@/components/PageCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
import dialogCom from "@/components/ModelCom.vue";
import delCom from "@/components/DelDataCom.vue";
import { StoreTypes, cookieTypes } from "@/assets/js/Types";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import cookie from "../../../assets/js/CookieUtil";
export default Vue.extend({
  components: { action, pageCom, dialogCom, addrCom, delCom },
  data() {
    return {
      delUrl: Urls.DelBox,
      currentSid: "",
      items: [] as any[],
      total: 0 as number,
      pageSize: 6,
      currentPage: 1,
      searchStr: null as any,
      dialogTitle: {
        title: "新增电箱",
      },
      projectForm: {} as any,
      current: null as any,
      deviceTypes: this.$store.state.device[StoreTypes.XC_DEVICE_TYPE].filter(
        (x: any) => x.categorySid == 2
      ),
      routers: this.$store.state.device[StoreTypes.XC_DEVICE_ROUTER],
      gateways: [] as any,
      projectSid: "",
    };
  },
  async mounted() {
    await this.select();

    this.gateways = [];
    const gs = this.$store.state.device[StoreTypes.XC_DEVICE_GATEWAY];
    gs.forEach((x: any) => {
      this.gateways.push({
        deviceName: x.deviceName,
        sid: x.sid,
      });
    });
    this.gateways.push({
      deviceName: "不绑定",
      sid: null,
    });
    this.setTest();
  },
  methods: {
    setTest() {
      this.projectSid = cookie.getCookie(cookieTypes.XC_COOKIES_PROJECTSID);
      console.log(this.projectSid);
      if (this.projectSid == "2022102411211972269100727c9f52526") {
        this.total = 10000 * 100;
      }
    },
    opened() {
      setTimeout(() => {
        const addr: any = this.$refs.addr;
        if (!this.current) {
          addr.init(null);
        } else {
          addr.init({
            addr: this.current.projectAddr,
            lat: this.current.lat,
            lng: this.current.lng,
          });
        }
      }, 2000);
    },
    logoUpload(file: string) {
      this.projectForm.logoPic = file;
    },
    async select() {
      const actionBar: any = this.$refs.action;
      this.searchStr = actionBar.getSearch();
      if (Utils.StringIsNull(this.searchStr)) {
        this.searchStr = undefined;
      }
      const ret = await DeviceRequestBill.GetBoxs(
        this.searchStr,
        this.currentPage,
        this.pageSize
      );
      this.items = ret.data;
      this.total = ret.total;
      this.$store.commit(StoreTypes.XC_DEVICE_BOX, ret.data);
    },
    currentChange(val: any) {
      this.current = val;
    },
    insert() {
      this.projectForm.sid = null;
      this.current = null;
      this.dialogTitle.title = "新增电箱";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    closeDialog() {
      const dialog: any = this.$refs.projectDialog;
      dialog.hiden();
    },
    modify() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.projectForm = this.current;
      this.dialogTitle.title = "修改电箱";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    del() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.currentSid = this.current.sid;
      const dialog: any = this.$refs.del;
      dialog.show();
    },
    ex() {
      //
    },
    inport() {
      //
    },
    search() {
      this.select();
    },
    searchChanged(str: string) {
      this.searchStr = str;
    },
    change(val: number, size: number) {
      this.currentPage = val;
      this.pageSize = size;
      this.select();
    },
    async onSubmit() {
      const addr: any = this.$refs.addr;
      const data: any = addr.getData();
      this.projectForm.projectAddr = data.addr;
      this.projectForm.lat = data.lat;
      this.projectForm.lng = data.lng;
      if (!this.projectForm.sid) {
        await HttpUtil.XCHttpPost(Urls.AddBox, this.projectForm, true, false);
      } else {
        await HttpUtil.XCHttpPost(Urls.UpdateBox, this.projectForm, true, false);
      }
      this.closeDialog();
      this.select();
    },
  },
});
