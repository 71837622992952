
import CookieUtil from "./CookieUtil";
import { cookieTypes } from "./Types";
import Utils from "./Utils";

class KsSignarl{
    private url = Utils.GetBaseUrl() + "/LightHub";
    private sign: any = null;
    private projectSid="";
    private interval:any=null;
    vue:any=null;
    constructor (vue:any){
        this.vue=vue;
    }
    onBroadcastAlarmDatas(data:any){
        this.vue.$store.commit("setAlarmList",data);
    }
    onLoopActionDatas(data:any){
        this.vue.$store.commit("setLoopList",data);
    }
    onDeviceStateData(data:any){
        this.vue.$store.commit("setDeviceState",data);
    }
    startSign() {
        return new Promise((res: any, rej: any) => {
            const signarl=require("@aspnet/signalr");
            if (!this.sign) {
                this.sign = new signarl.HubConnectionBuilder().withUrl(this.url).build();
                this.sign.serverTimeoutInMilliseconds = 60000;
                this.sign.on("BroadcastAlarmDatas",(data:any)=>{
                    this.onBroadcastAlarmDatas(data);
                })
                this.sign.on("BroadcastLoopState",(data:any)=>{
                    this.onLoopActionDatas(data);
                })
                this.sign.on("BroadcastDeviceLogin",(data:any)=>{
                    this.onDeviceStateData(data);
                });
                this.sign.start().then(() => { 
                    this.projectSid=CookieUtil.getCookie(cookieTypes.XC_COOKIES_PROJECTSID)||"";
                    this.sign.send("ListenProject",this.projectSid);
                    res() 
                })
                    .catch((err: any) => {
                    rej(err);
                })
            } else {
                res();
            }
        })
    }
    reStartSign(){
        if(this.interval||this.interval==0){
            window.clearInterval(this.interval);
        }
        this.interval=window.setInterval(()=>{
            if(this.sign){
                if(this.sign.state!="Connected"){
                    this.sign=null;
                    this.startSign().then(()=>{
                        this.reStartSign();
                    }).catch(()=>{
                        this.reStartSign();
                    })
                }else{
                    const psid:string=CookieUtil.getCookie(cookieTypes.XC_COOKIES_PROJECTSID)||""
                    if(psid!=this.projectSid){
                        this.projectSid=psid;
                        this.sign.send("ListenProject",this.projectSid);
                    }
                }
            }else{
                this.startSign().then(()=>{this.reStartSign()}).catch((err:any)=>{
                    this.reStartSign()});
            }
        },2000);
    }
}
export default KsSignarl;