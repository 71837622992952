
import Vue from 'vue'
import mapCom from "@/components/MapCom.vue"
import { StoreTypes } from '@/assets/js/Types'
export default Vue.extend({
    components:{mapCom},
    data(){
        return{
            addr:"",
            lat:0,
            lng:0,
            projectInfo: this.$store.state.project[StoreTypes.XC_PROJECT_CURRENT],
        }
    },
    methods:{
        init(data:any){
            data=data||{
                addr:this.projectInfo.projectAddr,
                lat:this.projectInfo.lat,
                lng:this.projectInfo.lng
            }
            this.addr=data.addr||this.projectInfo.projectAddr;
            this.lat=data.lat||this.projectInfo.lat;
            this.lng=data.lng||this.projectInfo.lng;
            const map:any=this.$refs.map;
            map.init();
            map.setCenter(this.lat,this.lng);
        },
        getData(){
            return {
                addr:this.addr,
                lat:this.lat,
                lng:this.lng
            }
        },
        setAddress(){
            const map:any=this.$refs.map;
            map.setAddress(this.addr);
        },
        mapMoveEnd(lat:any){
            this.lat=lat.lat;
            this.lng=lat.lng;
            // const map:any=this.$refs.map;
            // map.getAddressByPostion(this.lat,this.lng,(ret:any)=>{
            //     if(ret.info=='OK'){
            //         this.addr=ret.regeocode.formattedAddress;
            //     }
            // });
        }
    }
})
