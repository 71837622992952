
import Vue from "vue";
import { autoHover } from "@/assets/js/EchartUtil";
import { EchartInitModel } from "@/assets/js/Model/InnerModel.ts";
interface dataModel {
  echart: any;
  myChart: any;
}
export default Vue.extend({
  data() {
    const ret: dataModel = {
      echart: {},
      myChart: {}
    };
    return ret;
  },
  mounted() {
    this.init();
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  methods: {
    init() {
      this.echart = require("echarts");
      this.myChart = this.echart.init(this.$refs.chart);
    },
    setOption(data: EchartInitModel) {
      const opt: any = {};
      if (data.Colors) {
        opt.color = data.Colors;
      }
      if (data.TitleConfig) {
        opt.title = {
          text: data.TitleConfig.title
        };
      }
      opt.tooltip = {
        trigger: "items",
        axisPointer: {
          type: "shadow"
        },
      };
      opt.grid = {
        left: "3%",
        right: "4%",
        bottom: "3%",
        top: "45",
        containLabel: true
      };
      opt.textStyle = {
        color: "#fff"
      };
      opt.series = [] as any[];
      const stack = data.Data[0].Name;
      const legs: string[] = [];
      data.Data.forEach(x => {
        legs.push(x.Name);
        opt.series.push({
          name: x.Name,
          type: "pie",
          radius: ["30%", "50%"],
          avoidLabelOverlap: true,
          itemStyle: {
            borderRadius: 10
          },
          label: {
            show: true,
            textStyle:{
                color:"#fff"
            },
            formatter:' {d0}%'
          },
          labelLine: {
            show: true
          },
          data: x.Data
        });
      });
      opt.legend = data.LegendConfig;
      this.myChart.setOption(opt);
      autoHover(this.myChart, opt, 4, 3000);
    }
  }
});
