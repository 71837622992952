import UserRequestBill from "@/assets/js/Bill/UserRequestBill";
import CookieUtil from "@/assets/js/CookieUtil";
import { ProjectDetail, UserInfoDetail } from "@/assets/js/Model/ResponseModel";
import { cookieTypes, StoreTypes } from "@/assets/js/Types";
import Vue from "vue";
import { Commit } from "vuex";


interface state {
    [StoreTypes.XC_PROJECT_INFO]: ProjectDetail[],
    [StoreTypes.XC_USERINFO]: UserInfoDetail,
    [StoreTypes.XC_PROJECT_CURRENT]:ProjectDetail
    [StoreTypes.XC_PROJECT_CURRENT_FRESH]:number
}
const mutations = {
    [StoreTypes.XC_PROJECT_INFO]: (state: state, payload: ProjectDetail[]): void => {
        state[StoreTypes.XC_PROJECT_INFO] = payload;
    },
    [StoreTypes.XC_USERINFO]: (state: state, payload: UserInfoDetail): void => {
        state[StoreTypes.XC_USERINFO] = payload;
    },
    [StoreTypes.XC_PROJECT_CURRENT]: (state: state, payload: ProjectDetail): void => {
        state[StoreTypes.XC_PROJECT_CURRENT] = payload;
        CookieUtil.setCookie(cookieTypes.XC_COOKIES_PROJECTSID,payload.sid,7);
        location.reload();
    }
}
const getters = {
  [StoreTypes.XC_PROJECT_INFO]: (state: state): ProjectDetail[] => {
    return state[StoreTypes.XC_PROJECT_INFO];
  },
  [StoreTypes.XC_USERINFO]: (state: state): UserInfoDetail => {
    return state[StoreTypes.XC_USERINFO];
  },
  [StoreTypes.XC_PROJECT_CURRENT]: (state: state): ProjectDetail => {
    return state[StoreTypes.XC_PROJECT_CURRENT];
  },
  [StoreTypes.XC_PROJECT_CURRENT_FRESH]: (state: state)=>() => {
    return state[StoreTypes.XC_PROJECT_CURRENT_FRESH];
  }
}
const actions = {
    [StoreTypes.XC_PROJECT_INFO]: async (context: { commit: Commit; state: state }, fresh: boolean) :Promise<void> => {
        if (fresh || !context.state[StoreTypes.XC_PROJECT_INFO]) {
          const info= await UserRequestBill.GetUserInfo();
          context.state[StoreTypes.XC_PROJECT_INFO]=info.projects||[],
          context.state[StoreTypes.XC_USERINFO]=info.userInfo;
          if(info.projects&&info.projects.length!=0){
            const sid=CookieUtil.getCookie(cookieTypes.XC_COOKIES_PROJECTSID);
            if(sid){
              const project=info.projects.find(x=>x.sid==sid);
              if(project){
                context.state[StoreTypes.XC_PROJECT_CURRENT]=project;
              }else{
                CookieUtil.setCookie(cookieTypes.XC_COOKIES_PROJECTSID,info.projects[0].sid,7);
                context.state[StoreTypes.XC_PROJECT_CURRENT]=info.projects[0];
              }
            }else{
              CookieUtil.setCookie(cookieTypes.XC_COOKIES_PROJECTSID,info.projects[0].sid,7);
              context.state[StoreTypes.XC_PROJECT_CURRENT]=info.projects[0];
            }
          }
        }
      }
}
export default {
  getters:getters,
  mutations:mutations,
  actions:actions
}