
import Vue from "vue";
import action from "@/components/ActionBarCom.vue";
import pageCom from "@/components/PageCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
import dialogCom from "@/components/ModelCom.vue";
import delCom from "@/components/DelDataCom.vue";
export default Vue.extend({
  components: { action, pageCom, dialogCom },
  data() {
    return {
      checkedRoles: [] as string[],
      delUrl: Urls.DelUsers,
      currentSid: "",
      items: [] as any[],
      total: 0,
      pageSize: 6,
      currentPage: 1,
      searchStr: null as any,
      dialogTitle: {
        title: "新增项目"
      },
      projectForm: {} as any,
      current: null as any,
      projects: [] as any[]
    };
  },
  async mounted() {
    await this.getRoles();
    await this.select();
  },
  methods: {
    async role() {
      if(!this.current){
        throw "请选择一条数据";
      }
      const roles:any=await HttpUtil.XCHttpPost(Urls.GetUserRole,this.current.sid,true,false);
      this.checkedRoles=roles.roles;
      const dialog: any = this.$refs.roleDialog;
      dialog.show();
    },
    opened(){
        //
    },
    async getRoles() {
      const dfata: any = {
        page: 1,
        pageSize: 1000,
        pagination: true
      };
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetRoles,
        dfata,
        true,
        false
      );
      this.projects = ret.data;
    },
    async select() {
      const actionBar: any = this.$refs.action;
      this.searchStr = actionBar.getSearch();
      if (Utils.StringIsNull(this.searchStr)) {
        this.searchStr = null;
      }
      const data = {
        page: this.currentPage,
        pageSize: this.pageSize,
        pagination: true,
        conditions: !this.searchStr?[]:[
          {
            field: "userName",
            value: this.searchStr,
            conditionType: 7
          },
          {
            field: "displayName",
            value: this.searchStr,
            conditionType: 7
          },
          {
            field: "projectName",
            value: this.searchStr,
            conditionType: 7
          },
          {
            field: "contactPhone",
            value: this.searchStr,
            conditionType: 0
          }
        ]
      };
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetUsers,
        data,
        true,
        false
      );
      this.items = ret.data;
      this.total = ret.total;
    },
    currentChange(val: any) {
      this.current = val;
    },
    hiden() {
      const dialog: any = this.$refs.roleDialog;
      dialog.hiden();
    },
    change(val: number,size:number) {
      this.currentPage = val;
      this.pageSize=size;
      this.select();
    },
    async onSubmit() {
      const data = {
        sid: this.current.sid,
        projectSid: this.current.projectSid,
        projectName:  this.current.projectName,
        displayName:  this.current.displayName,
        userName: this.current.userName,
        roles: this.checkedRoles
      };
      await HttpUtil.XCHttpPost(Urls.UpdateUserRole,data,true,false);
      this.hiden();
    }
  }
});
