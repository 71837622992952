
import Vue from "vue";
import dialogCom from "@/components/ModelCom.vue";
import CookieUtil from "@/assets/js/CookieUtil";
import { cookieTypes } from "@/assets/js/Types";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
export default Vue.extend({
  props: ["title", "ftype", "noexcel"],
  components: { dialogCom },
  data() {
    return {
      formData: {
        FileType: this.ftype,
        File: null as any,
      },
    };
  },
  mounted() {
    this.formData.FileType = this.ftype;
  },
  methods: {
    open() {
      const dialog: any = this.$refs.fileDialog;
      dialog.show();
    },
    hiden() {
      const dialog: any = this.$refs.fileDialog;
      dialog.hiden();
    },
    onFileChange() {
      const file: any = this.$refs.file;
      this.formData.File = file.files[0];
    },
    async exportData() {
      if (!this.formData.File) {
        throw "请选择一个文件";
      }
      this.hiden();
      if (this.noexcel) {
        throw "操作成功";
      } else {
        await HttpUtil.XCHttpFormData(Urls.ImportExcel, this.formData, true, false);
      }
    },
  },
});
