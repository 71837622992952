
import Vue from 'vue'
import HttpUtil from '@/assets/js/HttpUtil';
import Urls from '@/assets/js/Urls';
import Utils from '@/assets/js/Utils';
import UserRequestBill from '@/assets/js/Bill/UserRequestBill';
import { ProjectDetail } from '@/assets/js/Model/ResponseModel';
import { StoreTypes, cookieTypes } from '@/assets/js/Types';
import CookieUtil from '@/assets/js/CookieUtil';
import project from '@/store/project';
export default Vue.extend({
    data(){
        return{
            loading:true,
            loadingText:this.$t("正在获取用户信息")
        }
    },
     mounted(){
         const projectInfo:ProjectDetail[]=this.$store.state.project[StoreTypes.XC_PROJECT_INFO];
         if(projectInfo&&projectInfo.length!=0){ 
            let sid:string=CookieUtil.getCookie(cookieTypes.XC_COOKIES_PROJECTSID)||"";
            let pro=projectInfo.find(x=>x.sid==sid);
            if(Utils.StringIsNull(sid)||!pro){
                sid=projectInfo[0].sid;
                CookieUtil.setCookie(cookieTypes.XC_COOKIES_PROJECTSID,sid,7);
                 pro=projectInfo[0];
            }
            if(pro.menu&&pro.menu.childrens&&pro.menu.childrens.length!=0){
                this.$store.dispatch(StoreTypes.XC_PROJECT_CURRENT,pro.sid);
                this.$router.push("/Admin");
            }else{
                this.loadingText="没有找到任何可用授权，请联系管理人员";
            }
         }else{
            this.loadingText="没有找到关联项目，请联系管理人员";
         }
    }
})
