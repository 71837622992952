
import Vue from "vue";
import sliderCom from "@/components/SliderCom.vue";
import box from "@/components/BoxCom.vue";
import policyCom from "./SenceComponents/PolicyCom.vue";
import policyLoopCom from "./SenceComponents/PolicyLoopCom.vue";
import taskCom from "./SenceComponents/TaskCom.vue";
import { StoreTypes } from "@/assets/js/Types";
export default Vue.extend({
  components: { sliderCom, box, policyCom ,taskCom,policyLoopCom},
  data() {
    return {
      currentSid: "",
      slider: [] as any[]
    };
  },
  mounted() {
    this.getMenu();
  },
  methods: {
    change(sid: string) {
      this.currentSid = sid;
    },
    getMenu() {
      const project = this.$store.state.project[StoreTypes.XC_PROJECT_CURRENT];
      if (project && project.menu) {
        if (project.menu.childrens && project.menu.childrens.length != 0) {
          const m: any = project.menu.childrens.find(
            (x: any) => x.path.toLowerCase() == this.$route.path.toLowerCase()
          );
          if (m) {
            this.slider = [];
            m.childrens.forEach((x: any) => {
              this.slider.push({
                sid: x.sid,
                name: x.menuName
              });
            });
            if(this.slider.length!=0){
                this.currentSid=this.slider[0].sid;
                const slider:any=this.$refs.slider;
                slider.setCurrentSid(this.slider[0].sid);
            }
          }
        }
      }
    }
  }
});
