import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/css/kslight.less";
import i18n from './assets/lans';
Vue.prototype.$message = ElementUI.Message;
Vue.prototype.$load = ElementUI.Loading.service;
Vue.config.errorHandler = (err) => {
  ElementUI.Message({
    message: err.message || `${err}`,
    type: "warning"
  });
}
Vue.use(ElementUI);
new Vue({
  router,i18n,store,
  render: h => h(App)
}).$mount('#app')

/**
 JLight
 */
//  import Vue from 'vue'
//  import ElementUI from 'element-ui';
//  import 'element-ui/lib/theme-chalk/index.css';
//  import App from './App.vue'
//  import router from './router/JLightRouter'
//  import store from './store'
//  import "@/assets/css/kslight.less";
//  import i18n from './assets/lans';
//  Vue.prototype.$message = ElementUI.Message;
//  Vue.prototype.$load = ElementUI.Loading.service;
//  Vue.config.errorHandler = (err) => {
//    ElementUI.Message({
//      message: err.message || `${err}`,
//      type: "warning"
//    });
//  }
//  Vue.use(ElementUI);
//  new Vue({
//   router,i18n,store,
//    render: h => h(App)
//  }).$mount('#app')