
import Vue from "vue";
import uploadPic from "@/components/UploadPicCom.vue";
import addrCom from "@/components/AddrCom.vue";
import action from "@/components/ActionBarCom.vue";
import pageCom from "@/components/PageCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
import dialogCom from "@/components/ModelCom.vue";
import delCom from "@/components/DelDataCom.vue";
import CookieUtil from "@/assets/js/CookieUtil";
import { cookieTypes } from "@/assets/js/Types";
export default Vue.extend({
  components: { action, pageCom, dialogCom, uploadPic, addrCom, delCom },
  data() {
    return {
      treeProps: {
        label: "menuName",
        children: "childrens"
      },
      delUrl: Urls.DelProject,
      currentSid: "",
      items: [] as any[],
      total: 0,
      pageSize: 6,
      currentPage: 1,
      searchStr: null as any,
      dialogTitle: {
        title: "新增项目"
      },
      projectForm: {} as any,
      current: null as any,
      enPower: false,
      menus:[] as any,
      menuPower:[] as any
    };
  },
  async mounted() {
    await this.select();
    //获取登录用户
    const userName = CookieUtil.getCookie(cookieTypes.XC_COOKIES_USERNAME);
    this.enPower =true;
  },
  methods: {
    opened2() {
      setTimeout(() => {
        const tree: any = this.$refs.menuTree;
        if (tree) {
          tree.setCheckedKeys(this.menuPower.menus);
        }
      }, 1000);
    },
    closePowerialog() {
      const dialog: any = this.$refs.power;
      dialog.hiden();
    },
    async onPowerSubmit() {
      const power: any = this.$refs.menuTree;
      this.menuPower.menus = power.getCheckedKeys();
      const obj={
        projectSid:this.current.sid,
        menus: power.getCheckedKeys()
      }
      await HttpUtil.XCHttpPost(
        Urls.UpdateProjectMenus,
        obj,
        true,
        false
      );
      this.closePowerialog();
    },
    checkChange(val: any, val1: any, val2: any) {
      // console.log(val, val1, val2);
    },
    async getMenus() {
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetProjectMenuTree,
        this.current.sid,
        true,
        false
      );
      this.menus = ret.childrens;
    },
    async power() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.getMenus();
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetProjectMenus,
        this.current.sid,
        true,
        false
      );
      this.menuPower = ret;
      const power: any = this.$refs.power;
      power.show();
    },
    opened() {
      setTimeout(() => {
        const addr: any = this.$refs.addr;
        if (!this.current) {
          addr.init(null);
        } else {
          addr.init({
            addr: this.current.projectAddr,
            lat: this.current.lat,
            lng: this.current.lng
          })
        }
      }, 2000);
    },
    logoUpload(file: string) {
      this.projectForm.logoPic = file;
    },
    async select() {
      const actionBar: any = this.$refs.action;
      this.searchStr = actionBar.getSearch();
      if (Utils.StringIsNull(this.searchStr)) {
        this.searchStr = null;
      }
      const data = {
        page: this.currentPage,
        pageSize: this.pageSize,
        pagination: true,
        conditions: !this.searchStr ? [] : [
          {
            field: "projectName",
            value: this.searchStr,
            conditionType: 7
          },
          {
            field: "platformName",
            value: this.searchStr,
            conditionType: 7
          },
          {
            field: "contacter",
            value: this.searchStr,
            conditionType: 0
          },
          {
            field: "contactPhone",
            value: this.searchStr,
            conditionType: 0
          },
          {
            field: "projectAddr",
            value: this.searchStr,
            conditionType: 7
          }
        ]
      };
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetProjects,
        data,
        true,
        false
      );
      this.items = ret.data;
      this.total = ret.total;
    },
    currentChange(val: any) {
      this.current = val;
    },
    insert() {
      this.projectForm.sid = null;
      this.current = null;
      this.dialogTitle.title = "新增项目";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    closeDialog() {
      const dialog: any = this.$refs.projectDialog;
      dialog.hiden();
    },
    modify() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.projectForm = this.current;
      this.dialogTitle.title = "修改项目";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    del() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.currentSid = this.current.sid;
      const dialog: any = this.$refs.del;
      dialog.show();
    },
    ex() {
      //
    },
    inport() {
      //
    },
    search() {
      this.select();
    },
    searchChanged(str: string) {
      this.searchStr = str;
    },
    change(val: number, size: number) {
      this.currentPage = val;
      this.pageSize = size;
      this.select();
    },
    async onSubmit() {
      const addr: any = this.$refs.addr;
      const data: any = addr.getData();
      this.projectForm.projectAddr = data.addr;
      this.projectForm.lat = data.lat;
      this.projectForm.lng = data.lng;
      if (!this.projectForm.sid) {
        await HttpUtil.XCHttpPost(Urls.AddProject, this.projectForm, true, false);
      } else {
        await HttpUtil.XCHttpPost(Urls.UpdateProject, this.projectForm, true, false);
      }
      this.closeDialog();
      this.select();
    },
  }
});
