export interface EchartInitModel{
    Data:EchartDataModel[];
    Style?:number,
    Labels?:string[],
    LegendConfig?:any,
    TitleConfig?:any,
    isXValue?:boolean,
    isInBar?:boolean,
    Colors?:string[],
    NoAuto?:boolean
}
export interface EchartDataModel{
    Name:string;
    Data:any[];
    Arear?:any
}
export interface KeyValueModel{
    Key:string;
    Value:any
}
export enum CtrlType{
    开灯,
    关灯,
    调光,
    读取数据,
    同步时间,
    读取时间,
    灯具状态,
    电能查询,
    日出日落时间查询,
    开回路,
    关回路,
}