
import Vue from "vue";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import pageCom from "@/components/PageCom.vue";
export default Vue.extend({
  props:["type"],
  components: { pageCom },
  data() {
    return {
      items: [] as any,
      search: null as any,
      currentPage: 1,
      pageSize: 6,
      total: 0,
      choosedItem:[] as string[]
    };
  },
  async mounted() {
    await this.getLights();
  },
  watch:{
    type(){
       this.getLights();
    }

  },
  methods: {
    async getLights() {
      const ret = await DeviceRequestBill.GetSofts(
        this.search,
        this.currentPage,
        this.pageSize,this.type
      );
      this.total = ret.total;
      this.items = ret.data;
    },
    async searchClick() {
      await this.getLights();
    },
    change(val: number,size:number) {
      this.currentPage = val;
      this.pageSize=size;
      this.getLights();
    },
    handleSelectionChange(val:any){
      this.choosedItem=[];
      val.forEach((x:any) => {
        this.choosedItem.push(x.sid);
      });
    },
    getData(){
      return this.choosedItem;
    }
  }
});
