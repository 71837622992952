import axios from "./Axios"
import { SupportRequest } from "./Model/RequestModel"
import { SupportResponse } from "./Model/ResponseModel"
import cookie from "../js/CookieUtil"
import { cookieTypes } from "./Types"
import router from "@/router"
const baseURL: string = process.env.NODE_ENV == "production" ? "" : "http://localhost:5000"

async function XCHttpPost<T, R>(url: string, data: T, isToken: boolean,noLoading=false): Promise<R> {
    //先包装请求参数
    let req: any = data;
    if (isToken) {
        const tokenData: SupportRequest<T> = {
            token: cookie.getCookie(cookieTypes.XC_COOKIES_ACCESSTOKEN)||"",
            projectSid:cookie.getCookie(cookieTypes.XC_COOKIES_PROJECTSID)||"",
            data: data
        };
        req = tokenData;
    }
    //post请求
    url = `${url}`;
    const config:any={};
    if(noLoading){
        config.loading=false;
    }else{
        config.loading=true;
    }
    const res = await axios.post<SupportResponse<R>>(url, req,config);
    if (res.status != 200) {
        throw `${res.statusText}`;
    }
    const ores:any=res;
    if(ores.data.Data){
        return Promise.resolve(ores.data); 
    }else{
        if(res.data.retCode==401){
            router.app.$router.push("/");
        }
        if (res.data.retCode != 0) {
            throw `${res.data.retMsg}`;
        }
        return Promise.resolve(res.data.data);
    }
}
async function XCHttpFormData(url:string,data:any,isToken:boolean,noLoading:boolean){
    const req:any=data;
    if(isToken){
        req.Token= cookie.getCookie(cookieTypes.XC_COOKIES_ACCESSTOKEN)||"",
        req.ProjectSid=cookie.getCookie(cookieTypes.XC_COOKIES_PROJECTSID)||""
    }
    url = `${url}`;
    const config:any={};
    if(noLoading){
        config.loading=false;
    }else{
        config.loading=true;
    }
    const res=await axios.postForm(url,req,config);
    if (res.status != 200) {
        throw `${res.statusText}`;
    }
    const ores:any=res;
    if(ores.data.Data){
        return Promise.resolve(ores.data); 
    }else{
        if(res.data.retCode==401){
            router.app.$router.push("/");
        }
        if (res.data.retCode != 0) {
            throw `${res.data.retMsg}`;
        }
        return Promise.resolve(res.data.data);
    }
}
export class RouteHook {
    actions: Array<string>;
    public constructor(actions: Array<string>) {
        this.actions = actions;
    }
    async action(store: any, fresh?: boolean) {
        for (let i = 0; i < this.actions.length; i++) {
            await store.dispatch(this.actions[i], fresh);
        }
    }
}
export default {
    XCHttpPost: XCHttpPost,
    XCHttpFormData:XCHttpFormData
}