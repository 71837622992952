
import Vue from "vue";
import action from "@/components/ActionBarCom.vue";
import pageCom from "@/components/PageCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
import dialogCom from "@/components/ModelCom.vue";
import delCom from "@/components/DelDataCom.vue";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import deviceList from "../ShareComponents/DeviceChooseCom.vue";
export default Vue.extend({
  components: { action, pageCom, dialogCom, delCom, deviceList },
  data() {
    return {
      delUrl: Urls.DeleteHardwareGroup,
      currentSid: "",
      items: [] as any[],
      total: 0,
      pageSize: 6,
      currentPage: 1,
      searchStr: null as any,
      dialogTitle: {
        title: "新增项目",
      },
      projectForm: {} as any,
      current: null as any,
      projects: [] as any[],
      deviceType: -1,
      choosedItem: [] as any,
      savedItem: [] as string[],
      logs: [] as any[],
    };
  },
  async mounted() {
    // await this.getProjects();
    await this.select();
  },
  methods: {
    async down() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      await HttpUtil.XCHttpPost(
        Urls.SetHardwareGroup,
        this.current.sid,
        true,
        false
      );
    },
    closeLogDialog() {
      const dialog:any=this.$refs.logDialog;
      dialog.hiden();
    },
    async logClick(sid: string) {
      const obj = this.items.find((x) => x.sid == sid);
      if (obj != null) {
        const detail: any = await HttpUtil.XCHttpPost(
          Urls.GetHardwareGroup,
          obj.sid,
          true,
          false
        );
        if (detail) {
          this.logs = detail.devices;
      const dialog:any=this.$refs.logDialog;
      dialog.show();
        }
      }
    },
    selectChange(val: any) {
      this.choosedItem = val;
    },
    opened() {
      const list: any = this.$refs.devList;
      list.getList();
    },
    async getProjects() {
      const dfata: any = {
        page: 1,
        pageSize: 1000,
        pagination: true,
      };
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetProjects,
        dfata,
        true,
        false
      );
      this.projects = ret.data;
    },
    async edit() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.deviceType = this.current.groupType;
      const detail: any = await HttpUtil.XCHttpPost(
        Urls.GetHardwareGroup,
        this.current.sid,
        true,
        false
      );
      this.savedItem = detail.devices;
      const dialog: any = this.$refs.editDialog;
      dialog.show();
    },
    async onEditSubmit() {
      let req = {
        sid: this.current.sid,
        devices: [] as any,
      };
      if (this.choosedItem != null) {
        this.choosedItem.forEach((x: any) => {
          req.devices.push(x.sid);
        });
      }
      await HttpUtil.XCHttpPost(Urls.EditHardwareGroup, req, true, false);
      this.closeEditDialog();
    },
    closeEditDialog() {
      const dialog: any = this.$refs.editDialog;
      dialog.hiden();
    },
    async select() {
      const actionBar: any = this.$refs.action;
      this.searchStr = actionBar.getSearch();
      const ret = await DeviceRequestBill.GetHards(
        this.searchStr,
        this.currentPage,
        this.pageSize
      );
      this.total = ret.total;
      this.items = ret.data;
    },
    currentChange(val: any) {
      this.current = val;
    },
    insert() {
      this.current = null;
      this.projectForm = {
        sid: null,
      };
      this.dialogTitle.title = "新增软件分组";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    closeDialog() {
      const dialog: any = this.$refs.projectDialog;
      dialog.hiden();
    },
    modify() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.projectForm = this.current;
      this.dialogTitle.title = "修改软件分组";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    del() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.currentSid = this.current.sid;
      const dialog: any = this.$refs.del;
      dialog.show();
    },
    ex() {
      //
    },
    inport() {
      //
    },
    search() {
      this.select();
    },
    searchChanged(str: string) {
      this.searchStr = str;
    },
    change(val: number, size: number) {
      this.currentPage = val;
      this.pageSize = size;
      this.select();
    },
    async onSubmit() {
      this.projectForm.hardwareGroupOrder *= 1;
      if (!this.projectForm.sid) {
        await HttpUtil.XCHttpPost(
          Urls.AddHardwareGroup,
          this.projectForm,
          true,
          false
        );
      } else {
        await HttpUtil.XCHttpPost(
          Urls.UpdateHardwareGroup,
          this.projectForm,
          true,
          false
        );
      }
      this.closeDialog();
      this.select();
    },
  },
});
