
import Vue from "vue";
import sliderCom from "@/components/SliderCom.vue";
import box from "@/components/BoxCom.vue";
import allCom from "./CtrComponents/AllGateway.vue";
// import allLoop from "./CtrComponents/AllLoop.vue";
import routerCom from "./CtrComponents/RouterCom.vue";
import groupCom from "./CtrComponents/GroupCtrCom.vue";
import dialogCom from "@/components/ModelCom.vue";
import { StoreTypes } from "@/assets/js/Types";
import Urls from "@/assets/js/Urls";
import { CtrlType } from "@/assets/js/Model/InnerModel";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import HttpUtil from "@/assets/js/HttpUtil";
export default Vue.extend({
  components: { sliderCom, box, allCom, routerCom, groupCom,dialogCom },
  data() {
    return {
      currentSid: "",
      selectType: 0,
      slider: [] as any[],
      chooseLoops:[] as any[],
      sunFormat:{
        sids:[] as string[],
        downOffset:0,
        riseOffset:0,
        enable:false
      }
    };
  },
  mounted() {
    this.getMenu();
    this.change("0");
  },
  methods: {
    change(sid: string) {
      this.currentSid = sid;
    },
    async freshData(){
      if(this.currentSid=="0"){
        const ref:any=this.$refs.all;
        const sids= ref.getData();
        await HttpUtil.XCHttpPost(Urls.ReadGateway, sids, true, false);
      }
      
    },
    async asyncData(){
      //
      if(this.currentSid=="0"){
        const ref:any=this.$refs.all;
        const sids= ref.getData();
        await HttpUtil.XCHttpPost(Urls.GetScreenDatas, sids, true, false);
      }
    },
    closeSunDialog(){

      const dialog:any=this.$refs.sunDialog;
      dialog.hiden();
    },
    async sunSet(){
      const dialog:any=this.$refs.sunDialog;
      dialog.show();
    },
    async submitSun(){
      if(this.currentSid=="0"){
        const ref:any=this.$refs.all;
        const sids= ref.getData();
        this.sunFormat.sids=sids;
        this.sunFormat.downOffset*=1;
        this.sunFormat.riseOffset*=1;
        await HttpUtil.XCHttpPost(Urls.SetSunPolicyEnable, this.sunFormat, true, false);
      }
      this.closeSunDialog();
    },
    async ctrLight(ctrType: CtrlType, val: number) {
      let ids: string[] = [];
      let ref: any = null;
      switch (this.currentSid) {
        case "0":
        ref = this.$refs.all;
          ids = ref.getData();
          var data={
            sids:ids,
            isOpen:ctrType== CtrlType.开回路?true:false,
            loopIds:this.chooseLoops,
            type:0
          };
          await HttpUtil.XCHttpPost(Urls.GatewayControlLoops,data,true,false);
          break;
        case "1":
          ref = this.$refs.loop;
          ids = ref.getData();
          var data2={
            sids:ids,
            isOpen:ctrType== CtrlType.开回路?true:false,
            loopIds:this.chooseLoops,
            type:3
          };
          await HttpUtil.XCHttpPost(Urls.GatewayControlLoops,data2,true,false);
          break;
        case "2":
          ref = this.$refs.group;
          ids = ref.getData();
          var data3={
            sids:ids,
            isOpen:ctrType== CtrlType.开回路?true:false,
            loopIds:this.chooseLoops,
            type:1
          };
          await HttpUtil.XCHttpPost(Urls.GatewayControlLoops,data3,true,false);
          
          break;
        case "3":
          ref = this.$refs.router;
          ids = ref.getData();
          var data4={
            sids:ids,
            isOpen:ctrType== CtrlType.开回路?true:false,
            loopIds:this.chooseLoops,
            type:2
          };
          await HttpUtil.XCHttpPost(Urls.GatewayControlLoops,data4,true,false);
          break;
      }
    },
    async asyncTime(){
      await this.ctrLight(CtrlType.同步时间,0);
      this.freshPage();
    },
    async getEnger(){
      await this.ctrLight(CtrlType.电能查询,0);
      this.freshPage();
    },
    async getTime(){
      await this.ctrLight(CtrlType.读取时间,0);
      this.freshPage();
    },
    async getState(){
      await this.ctrLight(CtrlType.灯具状态,0);
      this.freshPage();
    },
    async getSun(){
      
      await this.ctrLight(CtrlType.日出日落时间查询,0);
      this.freshPage();
    },
    async open() {
      await this.ctrLight(CtrlType.开回路, 0);
    },
    async close() {
      await this.ctrLight(CtrlType.关灯, 0);
    },
     freshPage(){
      if (this.currentSid == "2022082612011125132600405532cc6f0") {
        setTimeout(() => {
          const ref: any = this.$refs.all;
          ref.getLights();
        }, 3000);
      }
    },
    async read() {
      await this.ctrLight(CtrlType.读取数据, 0);
      if (this.currentSid == "2022082612011125132600405532cc6f0") {
        setTimeout(() => {
          const ref: any = this.$refs.all;
          ref.getLights();
        }, 3000);
      }
    },
    light() {
      this.$prompt("请输入亮度", "调光", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\d+/,
        inputErrorMessage: "请输入0-100之间的数值",
      })
        .then(async (value: any) => {
          if (value.value < 0 || value.value > 100) {
            this.$message("请输入0-100之间的数值");
          } else {
            try {
              await this.ctrLight(CtrlType.调光, value.value * 1);
            } catch (err: any) {
              this.$message(err);
            }
          }
        })
        .catch((err) => {
          throw err;
        });
    },
    getMenu() {
      this.slider.push({
        sid:"0",
        name:"所有网关"
      }),
      this.slider.push({
        sid:"1",
        name:"回路分组"
      }),
      this.slider.push({
        sid:"2",
        name:"网关分组"
      }),
      this.slider.push({
        sid:"3",
        name:"路段分组"
      })
    },
  },
});
