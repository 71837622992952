
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import pageCom from "@/components/PageCom.vue";
import Vue from "vue";
export default Vue.extend({
    components:{pageCom},
  props: ["dtype","choosed"],
  data() {
    return {
      items: [] as any[],
      search: "",
      pageSize: 6,
      total: 0,
      page: 1,
      currentChange:null as any
    };
  },
  // watch:{
  //   choosed(){
      
  //   }
  // },
  methods: {
    async searchClick() {
      await this.getList();
    },
    async change(page:number,size:number){
        this.page=page;
        this.pageSize=size;
        await this.getList();
    },
    handleSelectionChange(val:any){
      this.$emit("selectChange",val);
    },
    initItem(){
      //
    },
    async getList(noFresh?:boolean) {
        if(noFresh&&this.items.length>0){
            return ;
        }
        let req={
            page:this.page,
            pageSize:this.pageSize,
            conditions:[
            //   {
            //     field:"state",
            //     value:"1",
            //     conditionType:0 
            // }
            ] as any[],
            orderByConditions:[{
                field:"gatewaySid",
                asc:true
            }]
        }
        if(this.search&&this.search.length!=0){
          req.conditions.push({
                field:"loopName",
                value:this.search,
                conditionType:7 
            })
          req.conditions.push({
                field:"gatewayName",
                value:this.search,
                conditionType:7 
            })
        }
        const ret:any = await HttpUtil.XCHttpPost(Urls.GetGatewayLoopList,req,true,false);
        this.total = ret.total;
        this.items = ret.data;
        const array:string[]=[];
      this.$nextTick(()=>{
      if(this.choosed){
        this.choosed.forEach((x:string) => {
          const value=this.items.find(y=>y.sid==x);
          if(value){
            array.push(value);
        const table:any=this.$refs.table;
        table.toggleRowSelection(value);
          }
        });
      }

      })
    },
  },
});
