
import Vue from "vue";
import action from "@/components/ActionBarCom.vue";
import pageCom from "@/components/PageCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
import dialogCom from "@/components/ModelCom.vue";
import delCom from "@/components/DelDataCom.vue";
import { StoreTypes } from "@/assets/js/Types";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
export default Vue.extend({
  components: { action, pageCom, dialogCom, delCom },
  data() {
    return {
      delUrl: Urls.DelGroup,
      currentSid: "",
      items: [] as any[],
      total: 0,
      pageSize: 6,
      currentPage: 1,
      searchStr: null as any,
      dialogTitle: {
        title: "新增项目",
      },
      projectForm: {} as any,
      current: null as any,
    };
  },
  async mounted() {
    await this.select();
  },
  methods: {
    async select() {
      const actionBar: any = this.$refs.action;
      this.searchStr = actionBar.getSearch();
      if (Utils.StringIsNull(this.searchStr)) {
        this.searchStr = null;
      }
      const ret = await DeviceRequestBill.GetRouters(
        this.searchStr,
        this.currentPage,
        this.pageSize
      );
      this.items = ret.data;
      this.total = ret.total;
      this.$store.commit(StoreTypes.XC_DEVICE_ROUTER, ret.data);
    },
    currentChange(val: any) {
      this.current = val;
    },
    insert() {
      this.current = null;
      this.projectForm = {
        sid: null,
      };
      this.dialogTitle.title = "新增路段";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    closeDialog() {
      const dialog: any = this.$refs.projectDialog;
      dialog.hiden();
    },
    modify() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.projectForm = this.current;
      this.dialogTitle.title = "修改路段";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    del() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.currentSid = this.current.sid;
      const dialog: any = this.$refs.del;
      dialog.show();
    },
    ex() {
      //
    },
    inport() {
      //
    },
    search() {
      this.select();
    },
    searchChanged(str: string) {
      this.searchStr = str;
    },
    change(val: number, size: number) {
      this.currentPage = val;
      this.pageSize = size;
      this.select();
    },
    async onSubmit() {
      if (!this.projectForm.sid) {
        await HttpUtil.XCHttpPost(Urls.AddGroup, this.projectForm, true, false);
      } else {
        await HttpUtil.XCHttpPost(Urls.UpdateGroup, this.projectForm, true, false);
      }
      this.closeDialog();
      this.select();
    },
  },
});
