
import Vue from "vue";
import modelCom from "@/components/ModelCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
export default Vue.extend({
  components: { modelCom },
  props: ["url", "sid"],
    data(){
      return{
        batch:null as any
      }
    },
  methods: {
    show(bat:any) {
      const model: any = this.$refs.model;
      model.show();
      this.batch=bat;
    },
    hiden() {
      const model: any = this.$refs.model;
      model.hiden();
    },
    async onSubmit() {
      if(!this.batch){
      await HttpUtil.XCHttpPost(this.url, this.sid, true, false);
      this.hiden();
      this.$emit("deleted");
      }else{
        this.hiden();
        this.$emit("batchdel");
      }
    }
  }
});
