import { InfiniteScroll } from "element-ui";

function StringIsNull(data: string) {
    if (data == undefined || data == null || data.length == 0) { return true; }
    return false;
}
function ConsoleLog(data: any) {
    console.log(data);
}
function GetGuid() {
    let guid = "";
    for (let i = 1; i <= 32; i++) {
        const n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
    }
    return guid;
}
function GetPageReq(page: number, pageSize: number, search?: string, fileds?: string[], condition?: number): any {
    const req: any = {
        page: page,
        pageSize: pageSize,
        conditions: []
    }
    if (search && fileds && fileds.length != 0) {
        fileds.forEach(x => {
            let ctype= condition == undefined ? 7 : condition;
            if(x=='deviceTypeSid'){
                ctype=0;
            }
            req.conditions.push({
                field: x,
                value: search,
                conditionType:ctype
            })
        })
    }
    return req;
}
function FormatData(date: Date) {
    date = date || new Date();
    if (!date.getFullYear) {
        date = new Date();
    }
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = FixStart0( date.getHours());
    const minute = FixStart0( date.getMinutes());
    const second =FixStart0(  date.getSeconds());
    return [hour, ':', minute, ':', second].join('');
}
function FormatDataFull(date: Date) {
    if(!(date instanceof Date)){
        return date;
    }
    if (!date.getFullYear) {
        date = new Date();
    }
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = FixStart0( date.getHours());
    const minute = FixStart0( date.getMinutes());
    const second =FixStart0(  date.getSeconds());
    return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
}
function FixStart0(val:number){
    if(val>=10)
    {
        return val;
    }else{
        return `0${val}`;
    }
}
function GetBaseUrl() {
    return process.env.NODE_ENV == "production" ? "" : "https://localhost:5001"
}
function GetPointerDistance(pointer1: any, pointer2: any) {
    const distance = Math.sqrt(Math.pow((pointer1.lng - pointer2.lng) * 100000, 2) + Math.pow((pointer1.lat - pointer2.lat) * 100000, 2));
    return Math.round(distance);
}
function GetAllDistance(pointers: any[]) {
    let distance = 0;
    for (let i = 0; i < pointers.length - 1; i++) {
        distance += GetPointerDistance(pointers[i], pointers[i + 1]);
    }
    return distance;
}
function GetNextPosition(pointers: any[], count: number) {
    const ret: any[] = [];
    const distance = GetAllDistance(pointers);
    for (let i = 0; i < pointers.length - 1; i++) {
        const dis = GetPointerDistance(
            pointers[i],
            pointers[i + 1]
        );
        const bit = dis / distance;
        const rcount = (count);
        const zcount = Math.round(bit * rcount);
        ret.push({
            lat: pointers[i].lat,
            lng: pointers[i].lng
        })
        const lat1 = pointers[i].lat * 100000;
        const lng1 = pointers[i].lng * 100000;
        const lng2 = pointers[i + 1].lng * 100000;
        const lat2 = pointers[i + 1].lat * 100000;
        const lat = lat1 - lat2;
        const latbit = lat / (zcount);
        for (let j = 0; j < zcount - 1; j++) {
            let xvalue=0;
            if(lat1<lat2){
                xvalue = (lat1 - (latbit * (j + 1))) / 100000;
            }else{
                xvalue = (lat1 - (latbit * (j + 1))) / 100000;
            }
            const yvalue = GetYByX(pointers[i], pointers[i+1], xvalue);
            ret.push({
                lat: xvalue,
                lng: yvalue
            });
        }
    }
    return ret;
}
function GetYByX(pointerStart: any, pointerEnd: any, xvalue: number) {
    return ((xvalue - pointerEnd.lat) * (pointerStart.lng - pointerEnd.lng) / (pointerStart.lat - pointerEnd.lat)) + pointerEnd.lng;
}
function ConfirmMsg(vue:any,title:string,action:any,errorAction?:any){
    vue.$confirm(title,"提示",{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
    }).then(()=>{
        action();
    }).catch(()=>{
        if(errorAction){
            errorAction();
        }
        // vue.$message({
        //     type:"info",
        //     message:"已经取消操作"
        // })
    })
}
function PointerInPolygon(data:any[],pointer:any){
    let j=data.length-1;
    let oddNodes=false;
    for(let i=0;i<data.length;i++){
        if((data[i].lng<pointer.lng&&data[j].lng>=pointer.lng)||(data[j].lng<pointer.lng&&data[i].lng>=pointer.lng)
        ){
            if(data[i].lat+(pointer.lng-data[i].lng)/(data[j].lng-data[i].lng)*(data[j].lat-data[i].lat)<pointer.lat){
                oddNodes=!oddNodes;
            }
        }
        j=i;
    }
    return oddNodes;
}
function ArrayGroupBy(array:any[],f:any){
    const groups:any={};
    array.forEach((x:any)=>{
        const group=f(x);
        groups[group]=groups[group]||[];
        groups[group].push(x);
    });
    return groups;
}
export default {
    StringIsNull: StringIsNull,
    ConsoleLog: ConsoleLog,
    GetPageReq: GetPageReq,
    GetGuid: GetGuid,
    FormatData: FormatData,
    GetBaseUrl: GetBaseUrl,
    GetPointerDistance: GetPointerDistance,
    GetAllDistance: GetAllDistance,
    GetNextPosition: GetNextPosition,
    ConfirmMsg:ConfirmMsg,
    PointerInPolygon:PointerInPolygon,
    ArrayGroupBy:ArrayGroupBy,
    FormatDataFull:FormatDataFull
}