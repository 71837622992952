interface JG{
    Name:string,
    Creater:string,
    CreateTime:string,
    UpdateTime:string,
    State:string,
    Temp:string,
    Sid:string
}
function JGList():JG[]{
    return [
        {
            Name:"常平公园",
            Creater:"管理员",
            CreateTime:"2022/03/28",
            UpdateTime:"2022/04/25",
            State:"启用",
            Temp:"平时模式",
            Sid:"0"
        },
        {
            Name:"华林公园",
            Creater:"管理员",
            CreateTime:"2022/04/12",
            UpdateTime:"2022/05/25",
            State:"启用",
            Temp:"节假日模式",
            Sid:"1"
        },
        {
            Name:"铁路公园",
            Creater:"管理员",
            CreateTime:"2023/08/18",
            UpdateTime:"2023/10/02",
            State:"启用",
            Temp:"平时模式",
            Sid:"2"
        },
        {
            Name:"常平新公园",
            Creater:"管理员",
            CreateTime:"2023/12/02",
            UpdateTime:"2024/02/01",
            State:"启用",
            Temp:"节假日模式",
            Sid:"3"
        }
    ];
}
export default JGList;
