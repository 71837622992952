<template>
  <div :style="style" ref="alarmanimate">
    <div class="w0" :style="centerStyle">
      <img fluid :src="alarmpic" />
    </div>
    <div v-bind:class="['animate', 'wave']">
      <div :class="['w1', { animating: animating }]"></div>
      <div :class="['w2', { animating: animating }]"></div>
      <div :class="['w3', { animating: animating }]"></div>
      <div :class="['w4', { animating: animating }]"></div>
    </div>
  </div>
</template>
<style>
@keyframes opac {
  from {
    opacity: 1;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
  }

  to {
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
@-webkit-keyframes opac {
  from {
    opacity: 1;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
  }
  to {
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
.animate .w2 {
  -webkit-animation-delay: 0.3s;
  -o-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.animate .w3 {
  -webkit-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.animate .w4 {
  -webkit-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
}
.wave {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.wave * {
  border: 1px solid #fff;
  position: absolute;
  border-radius: 50%;
  background-color: #ff156a;
}
.animating {
  -webkit-animation: opac 1s infinite;
  animation: opac 1s infinite;
}
.w0 {
  position: absolute;
  border-radius: 50%;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  opacity: 1;
  text-align: center;
  z-index: 100;
  background-color: #ff156a;
}
.w1 {
  width: 40%;
  height: 40%;
  left: calc(50% - 20%);
  top: calc(50% - 20%);
  opacity: 0.6;
}
.w2 {
  width: 60%;
  height: 60%;
  left: calc(50% - 30%);
  top: calc(50% - 30%);
  opacity: 0.4;
}

.w3 {
  width: 80%;
  height: 80%;
  left: calc(50% - 40%);
  top: calc(50% - 40%);
  opacity: 0.3;
}
</style>
<script>
export default {
  props: ["right"],
  data: function () {
    return {
      animating: false,
      style: {
        width: "0px",
        height: "0px",
        position: "absolute",
        right: this.right,
      },
      centerStyle: {
        width: "0px",
        height: "0px",
      },
      alarmpic: require("@/assets/img/icon/ic_yjbj.png"),
    };
  },
  methods: {
    start(animate) {
      this.animating = animate;
    },
    get() {
      return this.animating;
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      var width = this.$refs.alarmanimate.parentElement.clientWidth;
      var height = this.$refs.alarmanimate.parentElement.clientHeight;
      var w = width;
      var h = height;
      if (width > height) {
        w = height;
      } else {
        h = width;
      }
      this.style.width = w + "px";
      this.style.height = h + "px";
      this.centerStyle.width = w * 0.3 + "px";
      this.centerStyle.height = w * 0.3 + "px";
      this.centerStyle.left = `calc(50% - ${(w * 0.3) / 2}px)`;
      this.centerStyle.top = `calc(50% - ${(w * 0.3) / 2}px)`;
      this.centerStyle["line-height"] = `${w * 0.3}px`;
    });
  },
};
</script>
