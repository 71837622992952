
import Vue from "vue";
import { ProjectDetail, UserInfoDetail } from "@/assets/js/Model/ResponseModel";
import { StoreTypes, cookieTypes } from "@/assets/js/Types";
import CookieUtil from "@/assets/js/CookieUtil";
import setItem from "@/components/MenuSetItemCom.vue";
import dialogCom from "@/components/ModelCom.vue"
import Utils from "@/assets/js/Utils";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
interface projects {
  sid: string;
  name: string;
}
interface dataModel {
  projectInfo?: ProjectDetail;
  projectInfos: projects[];
  iconAdmin: string;
  iconMsg: string;
  iconProj: string;
  iconSet: string;
  iconLan: string;
  lans: projects[];
  userInfo: UserInfoDetail;
  actions: projects[];
  lanTitle: string;
  modifyPassword:any,
}
export default Vue.extend({
  components: { setItem ,dialogCom},
  data() {
    const ret:any = {
      lanTitle: "",
      projectInfo: {
        logoPic: "",
        sid: "",
        menu: undefined,
        projectName: "",
        platformName: ""
      },
      userInfo: {
        displayName: "",
        isSuperUser: false,
        projectSid: "",
        userName: "",
        userSid: ""
      },
      iconAdmin: require("@/assets/img/icon/icon-admin.png"),
      iconMsg: require("@/assets/img/icon/icon-msg.png"),
      iconProj: require("@/assets/img/icon/icon-proj.png"),
      iconSet: require("@/assets/img/icon/icon-set.png"),
      iconLan: require("@/assets/img/icon/icon-lan.png"),
      projectInfos: [],
      lans: [
        {
          sid: "zh",
          name: "简体中文"
        },
        {
          sid: "en",
          name: "English"
        }
      ],
      actions: [
        {
          sid: "0",
          name: "修改密码"
        },
        {
          sid: "1",
          name: "安全退出"
        }
      ],
      modifyPassword:{
        oldPassword:"",
        newPassword:"",
        newPassword2:""
      }
    };
    return ret;
  },
  mounted() {
    this.getProjectInfo();
    const lan = CookieUtil.getCookie(cookieTypes.XC_COOKIES_LAN);
    if (lan) {
      this.lanClick(lan);
    }
  },
  methods: {
    getProjectInfo() {
      const ps: ProjectDetail[] = this.$store.state.project[
        StoreTypes.XC_PROJECT_INFO
      ];
      this.projectInfos = [];
      ps.forEach(x => {
        this.projectInfos.push({
          sid: x.sid,
          name: x.projectName 
        });
      });
      //   const sid = CookieUtil.getCookie(cookieTypes.XC_COOKIES_PROJECTSID);
      this.projectInfo = this.$store.state.project[
        StoreTypes.XC_PROJECT_CURRENT
      ];
      this.userInfo = this.$store.state.project[StoreTypes.XC_USERINFO];
    },
    projClick(command: string) {
      const ps: ProjectDetail[] = this.$store.state.project[
        StoreTypes.XC_PROJECT_INFO
      ];
      this.projectInfo = ps.find(x => x.sid == command);
      this.$store.commit(StoreTypes.XC_PROJECT_CURRENT, this.projectInfo);
      this.$store.commit("setFreshProject");
    },
    async userClick(command: string) {
      if(command=='0'){
        const userdialog:any=this.$refs.userdialog;
        userdialog.show();
      }else{
         await HttpUtil.XCHttpPost(Urls.Logout,"",true,false);
         this.$router.push("/");
      }
    },
    closeUserDialog(){
        const userdialog:any=this.$refs.userdialog;
        userdialog.hiden();},
    lanClick(command: string) {
      const lan = this.lans.find(x => x.sid == command);
      if (lan) {
        this.lanTitle = lan.name;
        this.$i18n.locale = command;
        CookieUtil.setCookie(cookieTypes.XC_COOKIES_LAN, command, 7);
      }
    },
    msgClick() {
      //
    },
    setClick(){
      this.$router.push("/Admin/Setting");
    },
    async modifyPasswordSubmit(){
      const form:any = this.$refs.form;
      var reslut = await form.validate();
      if(reslut){
        if(this.modifyPassword.newPassword!=this.modifyPassword.newPassword2){
          throw "两次密码输入不一致";
        }
        await HttpUtil.XCHttpPost(Urls.ChangePassword,this.modifyPassword,true,false);
        this.closeUserDialog();
      }
    }
  }
});
