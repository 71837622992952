
import Vue from "vue";
import action from "@/components/ActionBarCom.vue";
import pageCom from "@/components/PageCom.vue";
import modelCom from "@/components/ModelCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
import dialogCom from "@/components/ModelCom.vue";
import delCom from "@/components/DelDataCom.vue";
import { StoreTypes } from "@/assets/js/Types";
export default Vue.extend({
  components: { action, pageCom, dialogCom, delCom, modelCom },
  data() {
    return {
      delUrl: Urls.DelRole,
      currentSid: "",
      items: [] as any[],
      total: 0,
      pageSize: 6,
      currentPage: 1,
      searchStr: null as any,
      dialogTitle: {
        title: "新增项目"
      },
      projectForm: {} as any,
      current: null as any,
      projects: [] as any[],
      menuPower: null as any,
      menus: [] as any[],
      treeProps: {
        label: "menuName",
        children: "childrens"
      }
    };
  },
  async mounted() {
    await this.getProjects();
    await this.select();
  },
  methods: {
    async getMenus() {
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetProjectRoleMenuTree,
        this.current.projectSid,
        true,
        false
      );
      this.menus = ret.childrens;
    },
    async getProjects() {
      const dfata: any = {
        page: 1,
        pageSize: 1000,
        pagination: true
      };
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetProjects,
        dfata,
        true,
        false
      );
      this.projects = ret.data;
    },
    async select() {
      const actionBar: any = this.$refs.action;
      this.searchStr = actionBar.getSearch();
      if (Utils.StringIsNull(this.searchStr)) {
        this.searchStr = null;
      }
      const data = {
        page: this.currentPage,
        pageSize: this.pageSize,
        pagination: true,
        conditions:!this.searchStr?[]: [
          {
            field: "roleName",
            value: this.searchStr,
            conditionType: 7
          },
          {
            field: "projectName",
            value: this.searchStr,
            conditionType: 7
          }
        ]
      };
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetRoles,
        data,
        true,
        false
      );
      this.items = ret.data;
      this.total = ret.total;
    },
    currentChange(val: any) {
      this.current = val;
    },
    insert() {
      this.current = null;
      this.projectForm = {
        sid: null
      };
      this.dialogTitle.title="新增角色";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },
    closeDialog() {
      const dialog: any = this.$refs.projectDialog;
      dialog.hiden();
    },
    closePowerialog() {
      const dialog: any = this.$refs.power;
      dialog.hiden();
    },
    modify() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.projectForm = this.current;
      this.dialogTitle.title="修改角色";
      const dialog: any = this.$refs.projectDialog;
      dialog.show();
    },

    async power() {
      if (!this.current) {
        throw "请选择一条数据";
      }
    await this.getMenus();
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetRoleMenu,
        this.current.sid,
        true,
        false
      );
      this.menuPower = ret;
      const power: any = this.$refs.power;
      power.show();
    },
    opened() {
      setTimeout(() => {
        const tree: any = this.$refs.menuTree;
        if (tree) {
          tree.setCheckedKeys(this.menuPower.menus);
        }
      }, 1000);
    },
    del() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.currentSid = this.current.sid;
      const dialog: any = this.$refs.del;
      dialog.show();
    },
    ex() {
      //
    },
    inport() {
      //
    },
    search() {
      this.select();
    },
    searchChanged(str: string) {
      this.searchStr = str;
    },
    change(val: number,size:number) {
      this.currentPage = val;
      this.pageSize=size;
      this.select();
    },
    checkChange(val: any, val1: any, val2: any) {
      //
    },
    async onSubmit() {
      if (!this.projectForm.sid) {
        await HttpUtil.XCHttpPost(Urls.AddRole, this.projectForm, true, false);
      } else {
        await HttpUtil.XCHttpPost(
          Urls.UpdateRole,
          this.projectForm,
          true,
          false
        );
      }
      this.closeDialog();
      this.select();
    },
    async onPowerSubmit() {
      const power: any = this.$refs.menuTree;
      this.menuPower.menus = power.getCheckedKeys();
      const obj={
        projectSid:this.current.projectSid,
        sid:this.current.sid,
        menus: power.getCheckedKeys()
      }
      await HttpUtil.XCHttpPost(
        Urls.UpdateRoleMenu,
        obj,
        true,
        false
      );
      this.closePowerialog();
    }
  }
});
