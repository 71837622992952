
import Vue from "vue";
import action from "@/components/ActionBarCom.vue";
import pageCom from "@/components/PageCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import Utils from "@/assets/js/Utils";
import delCom from "@/components/DelDataCom.vue";
import { StoreTypes, cookieTypes } from "@/assets/js/Types";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import editCom from "../ShareComponents/EditGatewayCom.vue";
import uploadFile from "../ShareComponents/UploadFileCom.vue";
import cookie from "../../../assets/js/CookieUtil";
export default Vue.extend({
  components: { action, pageCom, delCom, editCom, uploadFile },
  data() {
    return {
      delUrl: Urls.DelGateway,
      currentSid: "",
      items: [] as any[],
      total: 0 as number,
      pageSize: 6,
      currentPage: 1,
      searchStr: null as any,
      choosedItem: [] as any[],
      dialogTitle: {
        title: "新增网关",
      },
      projectForm: {} as any,
      current: null as any,
      deviceTypes: this.$store.state.device[StoreTypes.XC_DEVICE_TYPE].filter(
        (x: any) => x.categorySid == 1
      ),
      routers: this.$store.state.device[StoreTypes.XC_DEVICE_ROUTER],
      projectSid: null as any,
    };
  },
  async mounted() {
    await this.select();
    await this.setTest();
  },
  methods: {
    setTest() {
      this.projectSid = cookie.getCookie(cookieTypes.XC_COOKIES_PROJECTSID);
      console.log(this.projectSid);
      if (this.projectSid == "2022102411211972269100727c9f52526") {
        this.total = 1428016;
      }
    },
    importData() {
      const upload: any = this.$refs.uploadFile;
      upload.open();
    },
    async exportData() {
      const ret: string = await HttpUtil.XCHttpPost(Urls.ExportExcel, 1, true, false);
      window.open(ret);
    },
    async temp() {
      const url = `${Utils.GetBaseUrl()}/templates/GatewayTemplte.xlsx`;
      window.open(url);
    },
    handleSelectionChange(val: any) {
      this.choosedItem = [];
      val.forEach((x: any) => {
        this.choosedItem.push(x.sid);
      });
    },
    async select() {
      const actionBar: any = this.$refs.action;
      this.searchStr = actionBar.getSearch();
      if (Utils.StringIsNull(this.searchStr)) {
        this.searchStr = undefined;
      }
      const ret = await DeviceRequestBill.GetGateways(
        this.searchStr,
        this.currentPage,
        this.pageSize
      );
      this.items = ret.data;
      this.total = ret.total;
      this.$store.commit(StoreTypes.XC_DEVICE_GATEWAY, ret.data);
      if (this)
        if (this.projectSid == "2022102411211972269100727c9f52526") {
          if (this.items.length == 0) {
            //
          }
        }
      this.setTest();
    },
    currentChange(val: any) {
      this.current = val;
    },
    insert() {
      this.projectForm = {};
      this.projectForm.sid = null;
      this.current = null;
      this.dialogTitle.title = "新增网关";
      const dialog: any = this.$refs.editCom;
      dialog.showDialog();
    },
    closeDialog() {
      const dialog: any = this.$refs.projectDialog;
      dialog.hiden();
    },
    modify() {
      if (!this.current) {
        throw "请选择一条数据";
      }
      this.dialogTitle.title = "修改网关";
      this.projectForm = this.current;
      const dialog: any = this.$refs.editCom;
      dialog.showDialog();
    },
    del() {
      const dialog: any = this.$refs.del;
      if (this.choosedItem && this.choosedItem.length == 0) {
        if (!this.current) {
          throw "请选择一条数据";
        }
        this.currentSid = this.current.sid;
        dialog.show();
      } else {
        dialog.show(true);
      }
    },
    async batchdel() {
      await HttpUtil.XCHttpPost(Urls.BatchDelete, this.choosedItem, true, false);
      this.select();
    },
    search() {
      this.select();
    },
    searchChanged(str: string) {
      this.searchStr = str;
    },
    change(val: number, size: number) {
      this.currentPage = val;
      this.pageSize = size;
      this.select();
    },
  },
});
