
import Vue from "vue";
export default Vue.extend({
  props: ["menu"],
  data() {
    return {
      check: false
    };
  },
  watch: {
    $route(to, from) {
      const path = this.menu.path.toLowerCase();
      const spath = this.$route.path.toLowerCase();
      this.check = path == spath;
    }
  },
  mounted() {
    const path = this.menu.path.toLowerCase();
    const spath = this.$route.path.toLowerCase();
    this.check = path == spath;
  }
});
