
import Vue from "vue";
import DeviceRequestBill from "@/assets/js/Bill/DeviceRequestBill";
import buttonCom from "@/components/ButtonCom.vue";
import box from "@/components/BoxCom.vue";
import pageCom from "@/components/PageCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
export default Vue.extend({
  components: { pageCom, box, buttonCom },
  data() {
    return {
      items: [] as any,
      search: null as any,
      currentPage: 1,
      pageSize: 6,
      total: 0,
      choosedItem: [] as string[],
      value1: null as any,
    };
  },
  async mounted() {
    const value = new Date();
    this.value1 = new Date(value.getFullYear(), value.getMonth(), value.getDate());
    await this.getLights();
  },
  methods: {
    getCondition() {
      const req = {
        page: this.currentPage,
        pageSize: this.pageSize,
        conditions: [] as any[],
        orderByConditions: [] as any[],
      };
      if (this.search) {
        req.conditions.push({
          field: "deviceTypeName",
          value: this.search,
          conditionType: 7,
        });
        req.conditions.push({
          field: "deviceName",
          value: this.search,
          conditionType: 7,
        });
        req.conditions.push({
          field: "alarmTypeName",
          value: this.search,
          conditionType: 7,
        });
      }
      req.conditions.push({
        field: "updateTime",
        value: this.value1,
        conditionType: 4,
        andCondition: true,
      });
      req.orderByConditions = [
        {
          field: "updateTime",
          asc: false,
        },
      ];
      return req;
    },
    async exportData() {
      const url: string = await HttpUtil.XCHttpPost(
        Urls.ExportAlarm,
        this.getCondition(),
        true,
        false
      );
      window.open(url);
    },
    async alarmAction(sid: string) {
      await HttpUtil.XCHttpPost(Urls.ResetLightState, sid, true, false);
      this.getLights();
    },
    async getLights() {
      const ret: any = await HttpUtil.XCHttpPost(
        Urls.GetDeviceAlarms,
        this.getCondition(),
        true,
        false
      );
      this.total = ret.total;
      this.items = ret.data;
    },
    async searchClick() {
      await this.getLights();
    },
    change(val: number, size: number) {
      this.currentPage = val;
      this.pageSize = size;
      this.getLights();
    },
    handleSelectionChange(val: any) {
      this.choosedItem = [];
      val.forEach((x: any) => {
        this.choosedItem.push(x.ctrlAddr);
      });
    },
    getData() {
      return this.choosedItem;
    },
  },
});
