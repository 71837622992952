
import Vue from "vue";
export default Vue.extend({
  props: ["bg", "title", "datefix", "button"],
  data() {
    return {
      ms: [] as any[],
      currentMonth: 1
    }
  },
  mounted() {
    const m = new Date().getMonth();
    for (var i = m; i >= 0; i--) {
      this.ms.push(i + 1);
    }
    this.currentMonth = m + 1;
  },
  methods: {
    handleCommand(command:any){
      this.currentMonth=command;
      this.$emit("datefix",'m',command);
    }
  },
});
