
import Vue from "vue";
import dialogCom from "@/components/ModelCom.vue";
import pageCom from "@/components/PageCom.vue";
import sliderCom from "@/components/SliderCom.vue";
import HttpUtil from "@/assets/js/HttpUtil";
import Urls from "@/assets/js/Urls";
import searchInput from "@/components/SearchInputCom.vue"
export default Vue.extend({
  props: ["devices", "routers","groups","boxs","isLoop","loops","isPra"],
  components: { dialogCom, pageCom, sliderCom ,searchInput},
  data() {
    return {
      type: 0,
      dialog: null as any,
      total: 0,
      pageSize: 6,
      items: [] as any[],
      search: "",
      currentChoose: 0,
      currentPage: 1,
      totalItem: [] as any[],
      slider: [] as any,
      choosedItem:[] as any,
      loopSid:null,
      paraForm:{
        curValue:50,
        volValue:50,
        totalPower:450
      }
    };
  },
  mounted() {
    this.dialog = this.$refs.dialog;
    this.choose(0);
  },
  methods: {
    opened(){
      //
    },
    onSubmit(){
        this.$emit("submit",this.choosedItem,this.loopSid,this.paraForm);
    },
    show() {
      this.dialog.show();
    },
    hiden() {
      this.dialog.hiden();
    },
    searchClick(searchStr:string) {
      this.search=searchStr;
      const list:any[]=this.TestSearch(this.search,this.totalItem);
      this.setDevice(list);
    },
    TestSearch(search:string,list:any[]){
      let data:any[]=[];
      list.map(item=>{
        const r1=item.deviceName.search(search);
        const r2=item.deviceTypeName.search(search);
        const r3=item.ctrlAddr.search(search);
        if(r1!=-1||r2!=-1||r3!=-1){
          data.push(item);
        }
      });
      return data;
    },
    handleSelectionChange(items:any) {
      this.choosedItem=items;
    },
    loopChange(val:string){
      this.$emit("loopChange",val);
    },
    async menuClick(sid:string){
        if(this.currentChoose==0){
            this.totalItem=this.devices;
        }
        else if(this.currentChoose==1){
            this.totalItem=this.devices.filter((x:any)=>x.groupSid==sid);
        }
        else if(this.currentChoose==2){
            const ret:any = await HttpUtil.XCHttpPost(Urls.GetSoft,sid,true,false);
            this.totalItem=this.devices.filter((x:any)=>{
                const exsit =ret.devices.find((y:string)=>y==x.sid);
                return exsit?true:false
            });
        }
        else if(this.currentChoose==3){
            this.totalItem=this.devices.filter((x:any)=>x.boxSid==sid);
        }
        this.setDevice();
    },
    choose(type: number) {
      this.currentChoose = type;
      if (this.currentChoose == 0) {
        this.slider = [
          {
            sid: "0",
            name: "所有灯具",
          },
        ];
      } else if (this.currentChoose == 1) {
        this.slider = [];
        this.routers.forEach((x: any) => {
          this.slider.push({
            sid: x.sid,
            name: x.groupName,
          });
        });
      }
      else if(this.currentChoose==2){
        this.slider=[];
        this.groups.forEach((x:any) => {
            this.slider.push({
                sid:x.sid,
                name:x.groupName
            })
        });
      }
      else if(this.currentChoose==3){
        this.slider=[];
        this.boxs.forEach((x:any) => {
            this.slider.push({
                sid:x.sid,
                name:x.deviceName
            })
        });
      }
      if(this.slider.length>0){
        this.menuClick(this.slider[0].sid);
      }else{
        this.totalItem=[];
        this.setDevice();
      }
    },
    setDevice(list?:any[]) {
      list=list||this.totalItem;
      let end = (this.currentPage - 1) * this.pageSize + this.pageSize;
      if (end >= this.devices.length) {
        end = this.devices.length;
      }
      this.items =list.slice(
        (this.currentPage - 1) * this.pageSize,
        end
      );
      this.total = list.length;
    },
    change(val: number, size: number) {
      this.currentPage = val;
      this.pageSize = size;
      this.setDevice();
    },
  },
});
