
import Vue from 'vue'
export default Vue.extend({
    props:["slider"],
    data(){
        return{
            currentSid:""
        }
    },
    methods:{
        change(sid:string){
            this.currentSid=sid;
            this.$emit("menuClick",sid);
        },
        setCurrentSid(sid:string){
            this.currentSid=sid;
        }
    }
})
