
import Vue from "vue";
import box from "@/components/BoxCom.vue"
export default Vue.extend({
    props:["title"],
    components:{box},
  data(){
    return{
        dialogVisible:false
    }
  },
  methods: {
    show() {
      this.dialogVisible=true;
    },
    hiden() {
      this.dialogVisible=false;
    },
    handleClose(){
        //
    },opened(){
      this.$emit("opened");
    },
    buttonHandle(){
      this.$emit("button");
    },
  }
});
